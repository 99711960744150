import { CustomSize } from '@/common/types';
import { Box, Image, ModalProps, useDisclosure } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import CrossOriginImage from './CrossOriginImage';

export type DisplayImageProps = {
  src: string;
  name: string;
  size: CustomSize;
  renderModal: (props: Pick<ModalProps, 'isOpen' | 'onClose'>) => ReactElement;
};

const DisplayImage: FC<DisplayImageProps> = (props: DisplayImageProps) => {
  const { src, name, size, renderModal } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Image
        as={CrossOriginImage}
        src={src}
        alt={name}
        maxWidth='100%'
        maxHeight='420px'
        w={size.width}
        h={size.height}
        objectFit='cover'
        crossOrigin='anonymous'
        style={{ display: 'block', cursor: 'pointer' }}
        onClick={onOpen}
      />
      {isOpen && renderModal({ isOpen, onClose })}
    </Box>
  );
};

export default DisplayImage;
