import { formatDateToLocaleDayAndWeekday } from '@/utils/date/date';
import useTranslation from '@/utils/i18n/useTranslation';
import { Flex, Text } from '@chakra-ui/react';

type MultiDaysViewDayLabelProps = {
  date: Date;
};

const MultiDaysViewDayLabel = ({ date }: MultiDaysViewDayLabelProps) => {
  const isToday = date.toDateString() === new Date().toDateString();
  const { i18n } = useTranslation();
  const dayAndWeekday = formatDateToLocaleDayAndWeekday(date, i18n.language);

  return (
    <Flex height='32px' width='100%' alignItems={'center'} justifyContent={'center'} px={2} py={1}>
      <Text
        fontSize={'xs'}
        lineHeight={'short'}
        color={'primary.800'}
        backgroundColor={isToday ? 'primary.100' : undefined}
        borderRadius={'base'}
        py={0.5}
        width={'50px'}
        textAlign={'center'}
      >
        {dayAndWeekday}
      </Text>
    </Flex>
  );
};

export default MultiDaysViewDayLabel;
