import { gql } from '@apollo/client';
import { Flex, Text } from '@chakra-ui/react';
import { useWorkOrderStatus } from '../../hooks/useWorkOrderStatus';
import WorkOrderStatusLabel from '../WorkOrderStatusLabel';
import { WorkOrderEventChip_WorkOrderFragment } from './WorkOrderEventChip.generated';

gql`
fragment WorkOrderEventChip_WorkOrder on WorkOrder {
  id
  status
  title
}
`;

type WorkOrderEventChipProps = {
  workOrder: WorkOrderEventChip_WorkOrderFragment;
  onClick: () => void;
};

const WorkOrderEventChip = ({ workOrder: { status, title }, onClick }: WorkOrderEventChipProps) => {
  const { getStatusAppearanceInfo } = useWorkOrderStatus();
  const appearanceInfo = getStatusAppearanceInfo(status);
  return (
    // NOTE: border-radiusは週・月跨ぎではなしのため、ここでは指定なし
    // border-radius指定はAlignerコンポーネントで行う
    <Flex
      gap={1}
      pl={0.5}
      pr={1.5}
      py={0.5}
      width={'100%'}
      backgroundColor={appearanceInfo.color}
      alignItems={'center'}
      onClick={onClick}
      cursor={'pointer'}
    >
      <WorkOrderStatusLabel status={status} />
      <Text
        fontSize={'sm'}
        color={'neutral.0'}
        lineHeight={'20px'}
        overflow={'hidden'}
        whiteSpace={'nowrap'}
        textOverflow={'ellipsis'}
        minH={'20px'}
      >
        {title}
      </Text>
    </Flex>
  );
};

export default WorkOrderEventChip;
