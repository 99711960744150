import theme from '@/plugin/chakra';
import { CALENDAR_HASH } from '@/routes/($lang)._app._index/route';
import { Flex, IconButton } from '@chakra-ui/react';
import { useNavigate } from '@remix-run/react';
import { FaListUl, FaRegCalendarAlt } from 'react-icons/fa';

export const WorkOrderViewType = {
  LIST: 'LIST',
  CALENDAR: 'CALENDAR',
} as const;

const AriaLabelRecord = {
  LIST: 'list',
  CALENDAR: 'calendar',
} as const;

const IconRecord = {
  LIST: FaListUl,
  CALENDAR: FaRegCalendarAlt,
} as const;

export type WorkOrderViewType = (typeof WorkOrderViewType)[keyof typeof WorkOrderViewType];

export type WorkOrderViewToggleProps = {
  selectedView: WorkOrderViewType;
};

const WorkOrderViewToggle = ({ selectedView }: WorkOrderViewToggleProps) => {
  const navigate = useNavigate();
  return (
    <Flex
      sx={{
        '> button': {
          borderRadius: 0,
          borderY: '1px solid',
          borderRight: '1px solid',
          borderLeft: 'none',
          borderColor: 'neutral.100',
        },
        '> button:first-of-type': {
          borderLeftRadius: 'md',
          borderLeft: '1px solid',
          borderColor: 'neutral.100',
        },
        '> button:last-of-type': { borderRightRadius: 'md' },
      }}
    >
      {Object.values(WorkOrderViewType).map((view) => {
        const Icon = IconRecord[view];
        return (
          <IconButton
            key={view}
            height={'auto'}
            minW={'auto'}
            icon={<Icon size={20} color={theme.colors.primary['500']} />}
            aria-label={AriaLabelRecord[view]}
            variant={selectedView === view ? 'solid' : 'outline'}
            onClick={() => {
              if (view === WorkOrderViewType.CALENDAR) {
                navigate(`/#${CALENDAR_HASH}`);
              } else {
                navigate('/');
              }
            }}
            p={1.5}
            sx={{ backgroundColor: selectedView === view ? 'primary.50' : 'neutral.0' }}
          />
        );
      })}
    </Flex>
  );
};

export default WorkOrderViewToggle;
