import { gql } from '@apollo/client';
import type { AssetDisplay_AssetFragment } from './AssetDispplay.generated';

gql`
  fragment AssetDisplay_Asset on Asset {
    name
    parentAsset {
      name
      parentAsset {
        name
        parentAsset {
          name
          parentAsset {
            name
          }
        }
      }
    }
  }
`;

export const AssetDisplay = ({ asset }: { asset: AssetDisplay_AssetFragment }) => {
  return <>{formatAssetName(asset)}</>;
};

const formatAssetName = (asset: AssetDisplay_AssetFragment): string => {
  if (!asset) return '';
  return asset.parentAsset ? `${formatAssetName(asset.parentAsset)} > ${asset.name}` : asset.name;
};
