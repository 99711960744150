import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useMemo } from 'react';
import MultiDaysEventChipAligner, {
  MultiDaysViewDayEvent,
} from './internal/MultiDaysEventChipAligner/MultiDaysEventChipAligner';
import MultiDaysEventCountDisplay, {
  MultiDaysEventCount,
} from './internal/MultiDaysEventCountDisplay/MultiDaysEventCountDisplay';
import MultiDaysGroupLabel from './internal/MultiDaysGroupLabel/MultiDaysGroupLabel';
import MultiDaysGroupTypeSelect, {
  MultiDaysGroupTypeSelectProps,
} from './internal/MultiDaysGroupTypeSelect/MultiDaysGroupTypeSelect';
import MultiDaysViewDayLabel from './internal/MultiDaysViewDayLabel/MultiDaysViewDayLabel';

export type MultiDaysViewDayGroup = {
  id: string;
  label: string;
  events: MultiDaysViewDayEvent[];
  counts: MultiDaysEventCount[];
};

export type MultiDaysDaysInViewProps =
  | {
      daysInView: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9;
    }
  | {
      daysInView: 7;
      startWeekDayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    };

type MultiDaysViewProps<GroupType extends string> = {
  groups: MultiDaysViewDayGroup[];
  startDateOfView: Date;
  groupTypeSelectProps: MultiDaysGroupTypeSelectProps<GroupType>;
  targetDate: Date;
  setTargetDate: Dispatch<SetStateAction<Date>>;
  daysInView: MultiDaysDaysInViewProps['daysInView'];
  targetGroup: string | null;
  setTargetGroup: Dispatch<SetStateAction<string | null>>;
};

const MultiDaysView = <GroupType extends string>(props: MultiDaysViewProps<GroupType>) => {
  const {
    groups,
    groupTypeSelectProps,
    targetDate,
    setTargetDate,
    startDateOfView,
    daysInView,
    setTargetGroup,
  } = props;

  const dates = useMemo(
    () =>
      Array.from({ length: daysInView }, (_, i) => dayjs(startDateOfView).add(i, 'day').toDate()),
    [startDateOfView, daysInView]
  );
  const { isMobile } = useScreenInfos();

  return (
    <Grid
      templateColumns={`minmax(auto, ${isMobile ? '120px' : '200px'}) repeat(${dates.length}, minmax(0, 1fr))`}
      // NOTE: グループがない場合も1行は必ず表示する
      templateRows={`auto repeat(${groups.length || 1}, minmax(0, 1fr))`}
      minW={isMobile ? `${daysInView * 50 + 100}px` : '1000px'}
      minH={'100%'}
      borderX={'1px solid'}
      borderColor={'neutral.200'}
    >
      <GridItem
        borderY={'1px solid'}
        borderColor={'neutral.200'}
        backgroundColor={'neutral.50'}
        gridColumnStart={1}
        gridRowStart={1}
        zIndex={3}
        position={'sticky'}
        top={0}
        left={0}
      >
        <MultiDaysGroupTypeSelect<GroupType> {...groupTypeSelectProps} />
      </GridItem>
      {dates.map((date, idx) => (
        <GridItem
          key={date.toDateString()}
          borderY={'1px solid'}
          borderColor={'neutral.200'}
          position={'sticky'}
          zIndex={2}
          top={0}
          gridColumnStart={idx + 2}
          gridRowStart={1}
          height={'auto'}
          onClick={() => {
            setTargetDate(date);
          }}
          backgroundColor={
            targetDate.toDateString() === date.toDateString() ? 'primary.50' : 'neutral.0'
          }
          cursor={'pointer'}
        >
          <MultiDaysViewDayLabel date={date} />
        </GridItem>
      ))}
      {(groups.length > 0 ? groups : [{ id: 'group', label: '' }]).map(({ id, label }, idx) => (
        <GridItem
          borderBottom={'1px solid'}
          borderColor={'neutral.200'}
          key={id}
          gridColumnStart={1}
          gridRowStart={idx + 2}
          position={'sticky'}
          zIndex={2}
          left={0}
        >
          <MultiDaysGroupLabel label={label} />
        </GridItem>
      ))}
      {!isMobile &&
        !!groups.length &&
        groups.map(({ id, events }, idx) => {
          return (
            <MultiDaysEventChipAligner
              key={id}
              gridProps={{
                gridRowStart: idx + 2,
                gridColumnStart: 2,
                gridColumnEnd: -1,
              }}
              events={events}
              dates={dates}
            />
          );
        })}
      {isMobile &&
        !!groups.length &&
        groups.map(({ id, counts }, idx) => {
          return (
            <MultiDaysEventCountDisplay
              key={id}
              gridProps={{
                gridRowStart: idx + 2,
                gridColumnStart: 2,
                gridColumnEnd: -1,
              }}
              counts={counts}
              onClick={(date) => {
                setTargetDate(date);
                setTargetGroup(id);
              }}
            />
          );
        })}
      {!groups.length && (
        <>
          <GridItem gridColumnStart={2} gridColumnEnd={-1} gridRowStart={2} gridRowEnd={3}>
            <NoTasksLabel />
          </GridItem>
        </>
      )}
      {dates.map((date, dIdx) =>
        groups.map(({ id }, gIdx) => {
          return (
            <GridItem
              borderBottom={'1px solid'}
              borderColor={'neutral.200'}
              key={'date' + dIdx + '-' + gIdx}
              gridColumnStart={dIdx + 2}
              gridRowStart={gIdx + 2}
              gridRowEnd={gIdx + 3}
              onClick={() => {
                setTargetDate(date);
                setTargetGroup(id);
              }}
              backgroundColor={
                targetDate.toDateString() === date.toDateString() ? 'primary.50' : undefined
              }
              cursor={'pointer'}
            />
          );
        })
      )}
    </Grid>
  );
};

const NoTasksLabel = () => {
  const { t_ns } = useTranslation(TASK);
  return (
    <Flex alignItems={'center'} justifyContent={'center'} height={'100%'}>
      <Text>{t_ns('calendar.no-tasks')}</Text>
    </Flex>
  );
};

export default MultiDaysView;
