import Link from '@/common/components/Link';
import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdMode, MdMoreVert } from 'react-icons/md';
import { WorkOrderDetailV2 } from './WorkOrderDetailV2';

export type WorkOrderDetailSideDrawerProps = {
  workOrderId: number;
  onClickEditCheckList?: () => void;
};

export const useWorkOrderDetailSideDrawer = (): {
  open: (props: WorkOrderDetailSideDrawerProps) => void;
  component: React.ReactElement;
} => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contentProps, setContentProps] = useState<WorkOrderDetailSideDrawerProps | null>(null);
  const { t } = useTranslation();

  return {
    open: (props) => {
      setContentProps(props);
      onOpen();
    },
    component: (
      <Drawer
        size={{ base: 'full', md: 'lg' }}
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        autoFocus={false}
      >
        <DrawerOverlay />

        <DrawerContent maxH='100dvh'>
          <DrawerHeader borderBottomWidth='1px'>
            <Flex pr={8}>
              {t('status.task-status')}
              {contentProps?.onClickEditCheckList && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    ml='auto'
                    size='sm'
                    colorScheme='gray'
                    variant='ghost'
                    fontSize={20}
                    aria-label='WorkOrder Menu'
                    icon={<MdMoreVert />}
                  />
                  <MenuList zIndex={2}>
                    <MenuItem
                      onClick={() => contentProps.onClickEditCheckList?.()}
                      fontSize='sm'
                      fontWeight='normal'
                      aria-label='Update WorkOrder'
                      icon={<MdMode />}
                    >
                      {t('actions.edit')}
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Flex>
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody p={0} bg='neutral.50'>
            <SuspenseWithSpinner>
              {contentProps && <WorkOrderDetailV2 {...contentProps} />}
            </SuspenseWithSpinner>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              {t('actions.close')}
            </Button>
            <Button colorScheme='primary' as={Link} to={`/#${contentProps?.workOrderId}`}>
              {t('to-task')}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    ),
  };
};
