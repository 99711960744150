import { gql } from '@apollo/client';
import type { ProductDisplay_ProductFragment } from './ProductDisplay.generated';

gql`
  fragment ProductDisplay_Product on Product {
    name
    parentProduct {
      name
      parentProduct {
        name
      }
    }
  }
`;

export const ProductDisplay = ({ product }: { product: ProductDisplay_ProductFragment }) => {
  return <>{formatProductName(product)}</>;
};

const formatProductName = (product: ProductDisplay_ProductFragment): string => {
  return product.parentProduct
    ? `${formatProductName(product.parentProduct)} > ${product.name}`
    : product.name;
};
