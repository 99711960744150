import Link from '@/common/components/Link';
import { useCurrencySymbol } from '@/modules/parts/hooks/useCurrencySymbol';
import { formatUnit } from '@/modules/parts/utils';
import { gql } from '@apollo/client';
import { Box, Flex, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { WorkOrderPartsDisplay_WorkOrderPartFragment } from './WorkOrderPartDisplay.generated';

gql`
  fragment WorkOrderPartsDisplay_WorkOrderPart on WorkOrderPart {
    quantity
    part {
      id
      name
      cost
      unit
      currencyCode
    }
  }
`;

export type Props = {
  workOrderPart: WorkOrderPartsDisplay_WorkOrderPartFragment;
};

export const WorkOrderPartsDisplay = ({ workOrderPart: part }: Props) => {
  const { combineCurrencySymbol } = useCurrencySymbol();
  const { t } = useTranslation();
  const {
    quantity,
    part: { id, name, cost, currencyCode, unit },
  } = part;

  return (
    <Box key={id} p={2} borderWidth={1} borderLeft='unset' borderRight='unset' borderTop='unset'>
      <HStack justifyContent='space-between'>
        <Stack align='stretch' textAlign='left'>
          <Flex>
            <Link to={`/parts#${part.part.id}`} color='primary.500'>
              {name}
            </Link>
            <Spacer />
          </Flex>

          <Flex my={1}>
            {cost !== undefined && (
              <Text fontSize='sm' letterSpacing='wide' color='neutral.500'>
                {combineCurrencySymbol(cost, currencyCode)}
              </Text>
            )}
            <Spacer />
          </Flex>
        </Stack>
        <HStack>
          <Spacer />
          <Text>{quantity}</Text>
          <Text>{formatUnit(unit) === '個' ? t('pieces') : formatUnit(unit)}</Text>
        </HStack>
      </HStack>
    </Box>
  );
};
