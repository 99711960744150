import {
  CalendarType,
  StartWeekDayOfWeekType,
} from '@/common/components/CalendarView/CalendarView';
import Select from '@/common/components/Select/Select';
import { formatDateToLocaleYearAndMonth } from '@/utils/date/date';
import { useModal } from '@/utils/hooks/useModal';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  useModalContext,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { IoSettingsSharp } from 'react-icons/io5';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';
import { MultiDaysDaysInViewProps } from '../../MultiDaysView';

type CalendarViewHeaderProps = {
  calendarType: CalendarType;
  setCalendarType: Dispatch<SetStateAction<CalendarType>>;
  startDateOfView: Date;
  setStartDateOfView: Dispatch<SetStateAction<Date>>;
  startWeekDayOfWeekType: StartWeekDayOfWeekType;
  setStartWeekDayOfWeekType: Dispatch<SetStateAction<StartWeekDayOfWeekType>>;
} & MultiDaysDaysInViewProps;

const CalendarViewHeader = (props: CalendarViewHeaderProps) => {
  const { startDateOfView, setStartDateOfView, daysInView } = props;
  const goToNextWeek = useCallback(
    () => setStartDateOfView(dayjs(startDateOfView).add(daysInView, 'day').toDate()),
    [startDateOfView, daysInView, setStartDateOfView]
  );
  const goToPrevWeek = useCallback(
    () => setStartDateOfView(dayjs(startDateOfView).subtract(daysInView, 'day').toDate()),
    [startDateOfView, daysInView, setStartDateOfView]
  );
  const goToCurrentWeek = useCallback(
    () =>
      setStartDateOfView(
        daysInView === 7
          ? dayjs().startOf('week').add(props.startWeekDayOfWeek, 'day').toDate()
          : dayjs().toDate()
      ),
    [daysInView, props, setStartDateOfView]
  );

  // NOTE: 7日の場合のみ一列目の曜日をstartDateOfViewに固定する
  useEffect(() => {
    if (daysInView === 7) {
      const weekDayDiff = dayjs(startDateOfView).day() - props.startWeekDayOfWeek;
      if (weekDayDiff !== 0) {
        setStartDateOfView(
          dayjs(startDateOfView)
            .startOf('week')
            .add(props.startWeekDayOfWeek, 'day')
            .subtract(weekDayDiff >= 0 ? 0 : 1, 'week')
            .toDate()
        );
      }
    }
  }, [daysInView, props, startDateOfView, setStartDateOfView]);

  const { t, i18n } = useTranslation(TASK);
  const yearAndMonth = formatDateToLocaleYearAndMonth(startDateOfView, i18n.language);

  return (
    <Flex alignItems='center' justifyContent={'space-between'} p={{ base: 2, md: 0 }}>
      <Flex flex={1} p={2}>
        <CalendarViewSettingButton {...props} />
      </Flex>
      <Box textAlign={'center'}>
        <Text
          color={'bold'}
          lineHeight={'shorter'}
          fontSize={{ base: 'medium', md: 'xl' }}
          fontWeight={'bold'}
        >
          {yearAndMonth}
        </Text>
      </Box>
      <Flex flex={1} justifyContent={'flex-end'} gap={2} alignItems={'center'}>
        <IconButton
          minW={'32px'}
          height={'32px'}
          onClick={goToPrevWeek}
          aria-label={t('date.prev-week')}
          icon={<MdOutlineArrowBackIos size={16} />}
        />
        <Button
          height={'32px'}
          onClick={goToCurrentWeek}
          py={1}
          display={{ base: 'none', md: 'block' }}
        >
          <Text color={'text.primary'} fontWeight={'semibold'}>
            {t('date.this-week')}
          </Text>
        </Button>
        <IconButton
          minW={'32px'}
          height={'32px'}
          onClick={goToNextWeek}
          p={2}
          aria-label={t('date.next-week')}
          icon={<MdOutlineArrowForwardIos size={16} />}
        />
      </Flex>
    </Flex>
  );
};

const CalendarViewSettingButton = (props: CalendarViewHeaderProps) => {
  const { element: modal, open } = useModal({
    Component: CalendarViewSettingDrawer,
  });
  return (
    <>
      <IoSettingsSharp cursor={'pointer'} size={16} onClick={() => open(props)} />
      {modal}
    </>
  );
};

type CalendarViewSettingFormValues = {
  calendarType: CalendarType;
  startWeekDayOfWeekType: StartWeekDayOfWeekType;
};

const CalendarViewSettingDrawer = (props: CalendarViewHeaderProps) => {
  const modalContext = useModalContext();
  const { setStartDateOfView } = props;
  const { t, t_ns } = useTranslation(TASK);
  const { control, handleSubmit } = useForm<CalendarViewSettingFormValues>({
    defaultValues: {
      calendarType: props.calendarType,
      startWeekDayOfWeekType: props.startWeekDayOfWeekType,
    },
  });
  const watchedCalendarType = useWatch({
    name: 'calendarType',
    control,
  });
  const onSubmit = (data: CalendarViewSettingFormValues) => {
    modalContext.onClose();
    // HACK: localStorageに保存する処理を次のタスクキューに回すことで、modalContext.onClose()→localStorage保存の順番を固定する
    setTimeout(() => {
      props.setCalendarType(data.calendarType);
      if (data.calendarType === CalendarType.WEEK) {
        props.setStartWeekDayOfWeekType(data.startWeekDayOfWeekType);
        // NOTE: ついでにstartDateOfViewを今日に直すことで、曜日変更を繰り返しことで週がどんどん前に移動することを防ぐ
      }
      setStartDateOfView(dayjs().toDate());
    }, 0);
  };
  return (
    <ModalContent>
      <ModalHeader fontWeight='semibold' borderBottom='1px solid' borderColor='neutral.200'>
        {t_ns('calendar.settings')}
      </ModalHeader>
      <ModalCloseButton top={4} />
      <ModalBody px={2}>
        <Flex direction='column' gap={4} px={6} py={2}>
          <Controller<CalendarViewSettingFormValues, 'calendarType'>
            name='calendarType'
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl>
                <Flex direction='column'>
                  <FormLabel>{t_ns('calendar.view-select')}</FormLabel>
                  <Select
                    value={value}
                    onChange={onChange}
                    options={[
                      { value: CalendarType.WEEK, label: t('date.week') },
                      { value: CalendarType.THREE_DAYS, label: t('date.three-days') },
                    ]}
                  />
                </Flex>
              </FormControl>
            )}
          />
          <Controller<CalendarViewSettingFormValues, 'startWeekDayOfWeekType'>
            name='startWeekDayOfWeekType'
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl>
                {watchedCalendarType === CalendarType.WEEK && (
                  <Flex direction='column'>
                    <FormLabel>{t_ns('calendar.start-weekday-of-week')}</FormLabel>
                    <Select
                      value={value}
                      onChange={onChange}
                      options={[
                        { value: StartWeekDayOfWeekType.SUNDAY, label: t('date.sunday-long') },
                        { value: StartWeekDayOfWeekType.MONDAY, label: t('date.monday-long') },
                        { value: StartWeekDayOfWeekType.TODAY, label: t('date.today') },
                        {
                          value: StartWeekDayOfWeekType.TODAY_IN_THE_MIDDLE,
                          label: t_ns('date.today-in-the-middle'),
                        },
                      ]}
                    />
                  </Flex>
                )}
              </FormControl>
            )}
          />
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button variant='outline' mr={3} onClick={modalContext.onClose}>
          {t('actions.cancel')}
        </Button>
        <Button colorScheme='primary' onClick={handleSubmit(onSubmit)}>
          {t('actions.save')}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

export default CalendarViewHeader;
