import { AcceptType, FileUploadZone } from '@/common/components/FileUploader';
import MediaSwiperWrapper from '@/common/components/MediaSwiperWrapper';
import { dataUriToBlob, uploadFile } from '@/utils/file/files';
import { IFile } from '@/utils/file/type';
import { t } from 'i18next';
import { type FC, memo, useState } from 'react';
import type { CustomFieldFileProps, CustomFieldFileValueType } from './CustomFieldFile.types';

export const validateFileRequired = (values: CustomFieldFileValueType[]) => values.length > 0;

const ACCEPT_TYPES: AcceptType[] = ['image', 'video', 'pdf'];

const CustomFieldFile: FC<CustomFieldFileProps> = (props: CustomFieldFileProps) => {
  const {
    required = false,
    handleError,
    onAddCustomFieldFileValues,
    onRemoveCustomFieldFileValue,
    getFileUploadUrls,
    onUpdateCustomFieldFileValue,
    customFieldId,
    values,
  } = props;

  const onAddFiles = (files: IFile[]) => {
    const localFiles = files.map(({ id, file, fileData }) => ({
      customFieldId,
      fileId: id,
      name: file.name,
      contentType: file.type,
      src: fileData,
    }));

    onAddCustomFieldFileValues(localFiles);
  };

  const onClickRemoveIcon = (fileId: string) => {
    onRemoveCustomFieldFileValue(fileId, customFieldId);
  };

  const [hasError, setHasError] = useState(false);
  if (validateFileRequired(values) && hasError) {
    handleError?.(customFieldId, '');
    setHasError(false);
  }
  if (!validateFileRequired(values) && !hasError) {
    if (required) {
      handleError?.(customFieldId, t('form.validation.required'));
      setHasError(true);
    }
  }

  return (
    <>
      <FileUploadZone
        addFiles={onAddFiles}
        getFileUploadUrls={getFileUploadUrls}
        acceptTypes={ACCEPT_TYPES}
      />
      <MediaSwiperWrapper
        mediaFiles={values}
        removeFileHandler={(fileId) => onClickRemoveIcon(fileId)}
        showEditInModal={!!onUpdateCustomFieldFileValue}
        editFileHandler={async (fileId, dataUri, contentType, name) => {
          const data = await getFileUploadUrls([{ contentType }]);
          const { id, url } = data[0];
          await uploadFile(url, dataUriToBlob(dataUri));

          await onUpdateCustomFieldFileValue?.(fileId, {
            fileId: id,
            contentType,
            name,
            src: dataUri,
          });
        }}
      />
    </>
  );
};

export default memo(CustomFieldFile);
