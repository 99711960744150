import { DisplayImageModal } from '@/common/components/DisplayImageModal';
import { CustomSize } from '@/common/types';
import { isPdfType, isVideoType } from '@/utils/file/type';
import { useFiles } from '@/utils/file/useFiles';
import { Text } from '@chakra-ui/react';
import { FC, useMemo, useState } from 'react';
import DisplayImage from './DisplayImage';
import DisplayPdfFile from './DisplayPdfFile';
import DisplayVideo from './DisplayVideo';

export type DisplayFileItemProps = {
  fileId: string;
  name: string;
  contentType: string;
  path?: string | null;
  size: CustomSize;
  src?: string | null;
};

const DisplayFileItem: FC<DisplayFileItemProps> = (props: DisplayFileItemProps) => {
  const { fileId, name, contentType, path, size, src } = props;
  const [localSrc, setSrc] = useState<string | null | undefined>(src);

  const { setFileUrlToSrcFiles } = useFiles();

  useMemo(() => {
    if (!src && !path) throw Error('src and path are both undefined');
    if (!src && path) {
      const file = { name, contentType, fileId, path };
      setFileUrlToSrcFiles([file]).then((files) => setSrc(files[0].src ? files[0].src : undefined));
    }
  }, [contentType, fileId, name, path, setFileUrlToSrcFiles, src]);

  if (!localSrc) return <></>;
  if (isVideoType(contentType)) {
    return <DisplayVideo key={fileId} src={localSrc} size={size} />;
  } else if (isPdfType(contentType)) {
    return (
      <>
        <DisplayPdfFile src={localSrc ?? ''} name={name} size={size} />
        <Text
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          width='100%'
          textAlign='center'
        >
          {name}
        </Text>
      </>
    );
  } else {
    return (
      <DisplayImage
        key={fileId}
        src={localSrc}
        name={name}
        size={size}
        renderModal={({ isOpen, onClose }) => {
          return (
            <DisplayImageModal
              isOpen={isOpen}
              onClose={onClose}
              initialSrc={localSrc}
              allFiles={[
                {
                  fileId,
                  name,
                  src: localSrc,
                },
              ]}
            />
          );
        }}
      />
    );
  }
};

export default DisplayFileItem;
