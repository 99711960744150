import { DisplayImageModal } from '@/common/components/DisplayImageModal';
import { CustomSize, MediaFileType, RequiredPick } from '@/common/types';
import { isPdfType, isVideoType } from '@/utils/file/type';
import { Box, Text, VStack } from '@chakra-ui/react';
import { CSSProperties, FC } from 'react';
import { SwiperSlide } from 'swiper/react';
import DisplayFileWrapper from './DisplayFileWrapper';
import DisplayImage from './DisplayImage';
import DisplayPdfFile from './DisplayPdfFile';
import DisplayVideo from './DisplayVideo';
import Swiper from './Swiper';

type MediaSwiperWrapperProps = {
  mediaFiles: MediaFileType[];
  showRemoveFileIcon?: boolean;
  showEditInModal?: boolean;
  removeFileHandler?: (fileId: string) => void;
  editFileHandler?: (
    fileId: string,
    dataUri: string,
    contentType: string,
    name: string
  ) => Promise<void>;
  slideDisplayMode?: SlideDisplayMode;
  displayName?: boolean;
};

export type SlideDisplayMode = 'single' | 'double';

const SLIDE_SIZE: CustomSize = {
  width: 260,
  height: 160,
};

const SLIDE_SIZE_SHORT: CustomSize = {
  width: 130,
  height: 160,
};

const SLIDE_STYLE: CSSProperties = { paddingBottom: '35px' };

const MediaSwiperWrapper: FC<MediaSwiperWrapperProps> = (props: MediaSwiperWrapperProps) => {
  const {
    mediaFiles,
    showRemoveFileIcon = true,
    showEditInModal = false,
    removeFileHandler,
    editFileHandler = async () => {},
    slideDisplayMode = 'single',
    displayName = false,
  } = props;

  const slideSize = slideDisplayMode === 'single' ? SLIDE_SIZE : SLIDE_SIZE_SHORT;

  const imageFiles = mediaFiles.filter((file) => {
    return file.contentType && !isVideoType(file.contentType) && !isPdfType(file.contentType);
  });

  return (
    <Box mt={5}>
      <Swiper slideSize={slideSize}>
        {mediaFiles.map((file) => {
          const { key, fileId, contentType, src, name } = file;
          if (!contentType) throw Error('contentType is required');
          return (
            <SwiperSlide key={key ?? fileId} style={SLIDE_STYLE}>
              <DisplayFileWrapper
                onClick={() => removeFileHandler && file.fileId && removeFileHandler(file.fileId)}
                showRemoveIcon={showRemoveFileIcon}
              >
                <VStack>
                  {src &&
                    (isVideoType(contentType) ? (
                      <DisplayVideo src={src} size={SLIDE_SIZE} />
                    ) : isPdfType(contentType) ? (
                      <DisplayPdfFile src={src} name={name} size={SLIDE_SIZE} />
                    ) : (
                      <DisplayImage
                        src={src}
                        name={name}
                        size={SLIDE_SIZE}
                        renderModal={({ isOpen, onClose }) => {
                          return (
                            <DisplayImageModal
                              initialSrc={src}
                              allFiles={imageFiles.filter(
                                (
                                  file
                                ): file is RequiredPick<MediaFileType, 'src' | 'name' | 'fileId'> =>
                                  !!file.src && !!file.fileId
                              )}
                              showEditInModal={showEditInModal}
                              onSaveEditedImage={(fileId, dataUri, contentType, name) =>
                                editFileHandler?.(fileId, dataUri, contentType, name)
                              }
                              isOpen={isOpen}
                              onClose={onClose}
                            />
                          );
                        }}
                      />
                    ))}
                  {(displayName || isPdfType(contentType)) && (
                    // TODO: 全てのファイルで、名前を表示するかどうかは名前の編集ができるようになってから考える
                    <Text
                      overflow='hidden'
                      textOverflow='ellipsis'
                      whiteSpace='nowrap'
                      width='100%'
                      textAlign='center'
                    >
                      {name}
                    </Text>
                  )}
                </VStack>
              </DisplayFileWrapper>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};
export default MediaSwiperWrapper;
