import theme from '@/plugin/chakra';
import useTranslation from '@/utils/i18n/useTranslation';
import { Box, Flex, Grid, GridItem, GridProps, Text } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa6';

export type MultiDaysEventCount = {
  date: Date;
  eventCount: number;
};

type MultiDaysEventCountDisplayProps = {
  gridProps: GridProps;
  counts: MultiDaysEventCount[];
  onClick: (date: Date) => void;
};

const MultiDaysEventCountDisplay = ({
  gridProps,
  counts,
  onClick,
}: MultiDaysEventCountDisplayProps) => {
  return (
    <Grid templateColumns={'subgrid'} gridAutoFlow={'column'} gap={0.5} {...gridProps}>
      {counts.map(({ date, eventCount }, idx) => {
        return (
          <GridItem
            key={date.toDateString()}
            gridColumnStart={idx + 1}
            onClick={() => onClick(date)}
          >
            <MultiDaysEventCountCell eventCount={eventCount} />
          </GridItem>
        );
      })}
    </Grid>
  );
};

type MultiDaysEventCountCellProps = {
  eventCount: number;
};

const MultiDaysEventCountCell = ({ eventCount }: MultiDaysEventCountCellProps) => {
  const { t } = useTranslation();
  if (eventCount === 0) {
    return <Box height={'100%'} minH={'32px'} width={'100%'} />;
  }

  return (
    <Flex
      height={'100%'}
      width={'100%'}
      minH={'32px'}
      textAlign='center'
      flexDir={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={1}
      p={1}
      cursor={'pointer'}
    >
      <Box zIndex={1}>
        <FaCircle size={6} color={theme.colors.neutral[400]} />
      </Box>
      <Text color={'text.secondary'} fontSize={'10px'} zIndex={1}>
        {eventCount}
        {t('event-unit')}
      </Text>
    </Flex>
  );
};

export default MultiDaysEventCountDisplay;
