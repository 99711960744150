import { Box } from '@chakra-ui/react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Select, { OptionProps, components } from 'react-select';

export type MultiDaysGroupTypeSelectProps<GroupType extends string> = {
  options: GroupType[];
  value: GroupType;
  onChange: (groupType: GroupType) => void;
  getLabel: (groupType: GroupType) => string;
};

type Option<GroupType extends string> = {
  value: GroupType;
  label: string;
};

const MultiDaysGroupTypeSelect = <GroupType extends string>({
  options: optionsProps,
  value: valueProps,
  onChange: onChangeProps,
  getLabel: getLabelProps,
}: MultiDaysGroupTypeSelectProps<GroupType>) => {
  const onChange = (newValue: Option<GroupType> | null) => {
    if (!newValue) throw new Error('newValue is undefined');
    onChangeProps(newValue.value);
  };
  const value = {
    value: valueProps,
    label: getLabelProps(valueProps),
  };

  const options = optionsProps.map((groupType) => ({
    value: groupType,
    label: getLabelProps(groupType),
  }));

  const getLabel = (groupType: Option<GroupType>) => groupType.label;

  return (
    <Select<Option<GroupType>, false>
      closeMenuOnSelect={true}
      value={value}
      onChange={onChange}
      options={options}
      css={{ width: '100%', border: 'none' }}
      getOptionLabel={getLabel}
      components={{
        Option: MultiDaysGroupTypeSelectOption<Option<GroupType>>,
        DownChevron: MdKeyboardArrowDown,
        IndicatorsContainer: (props) => (
          <Box p={0} height={8} width={8} sx={{ '& div': { p: 1, maxH: 8 } }}>
            <components.IndicatorsContainer {...props} />
          </Box>
        ),
        IndicatorSeparator: null,
        SelectContainer: (props) => (
          <Box>
            <components.SelectContainer {...props} />
          </Box>
        ),
        Control: (props) => (
          <Box
            fontWeight={'semibold'}
            lineHeight={'short'}
            sx={{
              '> div': {
                cursor: 'pointer',
                backgroundColor: 'transparent',
                borderWidth: 0,
                boxShadow: 'none',
                alignItems: 'center',
                minH: 8,
              },
            }}
          >
            <components.Control {...props} />
          </Box>
        ),
        Menu: (props) => (
          <Box py={1} borderRadius={'md'}>
            <components.Menu {...props} />
          </Box>
        ),
      }}
      isSearchable={false}
      menuPosition='fixed'
    />
  );
};

const MultiDaysGroupTypeSelectOption = <GroupType extends Option<string>>(
  props: OptionProps<GroupType, false>
) => {
  const { isSelected } = props;
  return (
    <Box
      sx={{
        '> div': {
          cursor: 'pointer',
          backgroundColor: isSelected ? 'primary.50' : 'transparent',
          color: 'neutral.800',
          lineHeight: 'base',
          ':active': {
            backgroundColor: 'primary.100',
          },
        },
      }}
    >
      <components.Option {...props} />
    </Box>
  );
};

export default MultiDaysGroupTypeSelect;
