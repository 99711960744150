import { assertNever } from '@/utils/types/types';
import { Box, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import MultiDaysView, {
  MultiDaysViewDayGroup,
} from './internal/common/MultiDaysView/MultiDaysView';
import CalendarViewHeader from './internal/common/MultiDaysView/internal/CalendarViewHeader/CalendarViewHeader';
import { MultiDaysGroupTypeSelectProps } from './internal/common/MultiDaysView/internal/MultiDaysGroupTypeSelect/MultiDaysGroupTypeSelect';

export const CalendarType = {
  WEEK: 'WEEK',
  THREE_DAYS: 'THREE_DAYS',
} as const;
export type CalendarType = (typeof CalendarType)[keyof typeof CalendarType];

export const StartWeekDayOfWeekType = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TODAY: 'TODAY',
  TODAY_IN_THE_MIDDLE: 'TODAY_IN_THE_MIDDLE',
} as const;
export type StartWeekDayOfWeekType =
  (typeof StartWeekDayOfWeekType)[keyof typeof StartWeekDayOfWeekType];

type CalendarViewProps<GroupType extends string> = {
  groups: MultiDaysViewDayGroup[];
  groupTypeSelectProps: MultiDaysGroupTypeSelectProps<GroupType>;
  startDateOfView: Date;
  setStartDateOfView: Dispatch<SetStateAction<Date>>;
  calendarType: CalendarType;
  setCalendarType: Dispatch<SetStateAction<CalendarType>>;
  targetDate: Date;
  setTargetDate: Dispatch<SetStateAction<Date>>;
  targetGroup: string | null;
  setTargetGroup: Dispatch<SetStateAction<string | null>>;
};

export const getCalendarDaysInView = (calendarType: CalendarType) => {
  switch (calendarType) {
    case CalendarType.WEEK:
      return 7;
    case CalendarType.THREE_DAYS:
      return 3;
    default:
      assertNever(calendarType);
  }
};

export const getStartWeekDayOfWeek = (startWeekDayOfWeekType: StartWeekDayOfWeekType) => {
  switch (startWeekDayOfWeekType) {
    case StartWeekDayOfWeekType.SUNDAY:
      return 0;
    case StartWeekDayOfWeekType.MONDAY:
      return 1;
    case StartWeekDayOfWeekType.TODAY:
      return dayjs().day();
    case StartWeekDayOfWeekType.TODAY_IN_THE_MIDDLE:
      return (dayjs().day() >= 3 ? dayjs().day() - 3 : dayjs().day() + 4) as
        | 0
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6;
    default:
      assertNever(startWeekDayOfWeekType);
  }
};

const CalendarView = <GroupType extends string>(props: CalendarViewProps<GroupType>) => {
  const [startWeekDayOfWeekType, setStartWeekDayOfWeekType] =
    useLocalStorage<StartWeekDayOfWeekType>(
      'calendar-start-weekday-of-week',
      StartWeekDayOfWeekType.SUNDAY
    );
  return (
    <Flex
      height={'100%'}
      flexDir='column'
      gap={{ base: 0, md: 4 }}
      borderY={'1px solid'}
      borderColor={'neutral.200'}
      py={{ base: 0, md: 3.5 }}
      px={{ base: 0, md: 4 }}
    >
      <Box flexShrink={0}>
        <CalendarViewHeader
          calendarType={props.calendarType}
          setCalendarType={props.setCalendarType}
          startDateOfView={props.startDateOfView}
          setStartDateOfView={props.setStartDateOfView}
          startWeekDayOfWeekType={startWeekDayOfWeekType}
          setStartWeekDayOfWeekType={setStartWeekDayOfWeekType}
          daysInView={getCalendarDaysInView(props.calendarType)}
          startWeekDayOfWeek={getStartWeekDayOfWeek(startWeekDayOfWeekType)}
        />
      </Box>
      <Box
        flexGrow={1}
        overflow={'scroll'}
        sx={{
          '&::-webkit-scrollbar': {
            height: '0',
            width: '0',
          },
        }}
      >
        <InternalCalendarView {...props} />
      </Box>
    </Flex>
  );
};

const InternalCalendarView = <GroupType extends string>(props: CalendarViewProps<GroupType>) => {
  switch (props.calendarType) {
    case CalendarType.WEEK:
    case CalendarType.THREE_DAYS:
      return (
        <MultiDaysView<GroupType>
          {...props}
          daysInView={getCalendarDaysInView(props.calendarType)}
        />
      );
    default:
      assertNever(props.calendarType);
  }
};

export default CalendarView;
