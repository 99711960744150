import CrossOriginImage from '@/common/components/CrossOriginImage';
import ImageEditor, { Editor } from '@/common/components/ImageEditor';
import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { MediaFileType, RequiredPick } from '@/common/types';
import { downloadFile } from '@/utils/file/pdf';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import {
  Box,
  Button,
  CloseButton,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Spinner,
} from '@chakra-ui/react';
import { useCallback, useRef, useState } from 'react';
import { MdArrowBack, MdArrowForward, MdDownload } from 'react-icons/md';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

export function DisplayImageModal({
  initialSrc,
  allFiles,
  showEditInModal,
  onSaveEditedImage,
  isOpen,
  onClose,
}: {
  initialSrc: string;
  allFiles: RequiredPick<MediaFileType, 'name' | 'src' | 'fileId'>[];
  showEditInModal?: boolean;
  onSaveEditedImage?: (
    fileId: string,
    dataUri: string,
    contentType: string,
    name: string
  ) => Promise<void>;
} & Pick<ModalProps, 'isOpen' | 'onClose'>) {
  const { t } = useTranslation();
  const [mode, setMode] = useState<'viewer' | 'editor'>('viewer');
  const [imageIndex, setImageIndex] = useState(
    allFiles.findIndex((file) => file.src === initialSrc)
  );

  const currentFile = allFiles[imageIndex];

  const editor = useRef<Editor | null>(null);
  const [isSavingEditedImage, setIsSavingEditedImage] = useState(false);

  const { isMobile } = useScreenInfos();

  const onEditorReady = useCallback((e: Editor) => {
    editor.current = e;
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={mode === 'viewer'}
      size={{ base: 'full', md: '6xl' }}
      isCentered
      allowPinchZoom
    >
      <ModalOverlay />
      <ModalContent bg='neutral.700'>
        <ModalHeader display='flex' justifyContent='right' alignItems='center' gap={2}>
          <IconButton
            variant='outline'
            colorScheme='primary'
            size='sm'
            aria-label={t('download')}
            onClick={() => downloadFile(currentFile.src, currentFile.name)}
            icon={<MdDownload />}
          />
          {showEditInModal &&
            (mode === 'viewer' ? (
              <>
                <Button
                  colorScheme='primary'
                  variant='outline'
                  size='sm'
                  onClick={() => setMode('editor')}
                >
                  {t('actions.edit')}
                </Button>
              </>
            ) : (
              <Button
                colorScheme='primary'
                size='sm'
                disabled={isSavingEditedImage}
                onClick={async () => {
                  setIsSavingEditedImage(true);
                  if (editor.current) {
                    const data = editor.current.export(currentFile.name);
                    await onSaveEditedImage?.(
                      currentFile.fileId,
                      data.dataUri,
                      data.contentType,
                      data.name
                    );
                  }
                  setMode('viewer');
                  setIsSavingEditedImage(false);
                }}
              >
                {isSavingEditedImage ? <Spinner /> : t('actions.save')}
              </Button>
            ))}
          <CloseButton bg='neutral.400' zIndex='100' onClick={onClose} />
        </ModalHeader>
        <ModalBody display='flex' p={0} justifyContent='right' alignItems='center'>
          {mode === 'viewer' ? (
            <Box position='relative' width='100%'>
              <TransformWrapper initialScale={1} centerOnInit={true} centerZoomedOut={true}>
                <TransformComponent
                  wrapperStyle={{ width: '100%', height: isMobile ? '90svh' : '80vh' }}
                  contentStyle={{
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Image
                    as={CrossOriginImage}
                    crossOrigin='anonymous'
                    src={currentFile.src}
                    alt={currentFile.name}
                    objectFit='contain'
                    onClick={() => window.open(currentFile.src)}
                  />
                </TransformComponent>
              </TransformWrapper>
              {imageIndex > 0 && (
                <IconButton
                  aria-label='Left Arrow'
                  icon={<MdArrowBack />}
                  position='absolute'
                  top='50%'
                  left='16px'
                  transform='translateY(-50%)'
                  onClick={() => setImageIndex((current) => Math.max(0, current - 1))}
                />
              )}
              {imageIndex < allFiles.length - 1 && (
                <IconButton
                  aria-label='Left Arrow'
                  icon={<MdArrowForward />}
                  position='absolute'
                  top='50%'
                  right='16px'
                  transform='translateY(-50%)'
                  onClick={() =>
                    setImageIndex((current) => Math.min(allFiles.length - 1, current + 1))
                  }
                />
              )}
            </Box>
          ) : (
            <Box w='100%' h={isMobile ? '90svh' : '80vh'}>
              <SuspenseWithSpinner>
                <ImageEditor src={currentFile.src} onEditorReady={onEditorReady} />
              </SuspenseWithSpinner>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
