import AvatarList from '@/common/components/AvatarList';
import { RequestStatus, WorkOrderStatus } from '@/graphql/types';
import { IDisplayGroup } from '@/modules/groups';
import { IDisplayUser } from '@/modules/users';
import { useWorkOrderStatus } from '@/modules/workOrders/hooks/useWorkOrderStatus';
import { REQUESTS } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { Flex, Text } from '@chakra-ui/react';
import { useRequestStatus } from '../hooks/useRequestStatus';

export type RequestAssigneesLabelProps = {
  assignees: IDisplayUser[];
  workOrderAssignees: IDisplayUser[];
  groups: IDisplayGroup[];
  workOrderStatus?: WorkOrderStatus;
  requestStatus: RequestStatus;
};

const DISPLAYING_ICON_NUM = 3;
// NOTE: FULL_NAME_THRESHOLDを修正時にi18nのrequest-assignee-labelも修正すること
const FULL_NAME_THRESHOLD = 2;

export const RequestAssigneesLabel = ({
  assignees,
  workOrderAssignees,
  groups,
  requestStatus,
  workOrderStatus,
}: RequestAssigneesLabelProps) => {
  const targetAssignees = workOrderStatus ? workOrderAssignees : assignees;
  const namesInLabel = workOrderStatus
    ? workOrderAssignees.map(({ name }) => name)
    : groups.concat(assignees).map(({ name }) => name);
  const { getStatusAppearanceInfo: getRequestAppearanceInfo } = useRequestStatus();
  const { label: requestStatusLabel } = getRequestAppearanceInfo(requestStatus);
  const { getStatusAppearanceInfo: getWorkOrderAppearanceInfo } = useWorkOrderStatus();
  const status = workOrderStatus || requestStatus;
  const workOrderStatusLabel = workOrderStatus
    ? getWorkOrderAppearanceInfo(workOrderStatus).label
    : null;
  const statusLabel = workOrderStatusLabel || requestStatusLabel;
  const { t_ns } = useTranslation(REQUESTS);

  if (!namesInLabel.length) {
    return null;
  }

  if (requestStatus === RequestStatus.Canceled) {
    return null;
  }

  return (
    <Flex alignItems='flex-start'>
      <AvatarList users={targetAssignees} iconThreshold={DISPLAYING_ICON_NUM} />
      <Text as='span' fontSize='sm' lineHeight='shorter'>
        {t_ns(
          `request-assignee-label.${namesInLabel.length > 2 ? 'more' : namesInLabel.length}-user`,
          {
            firstUser: namesInLabel.at(0),
            secondUser: namesInLabel.at(1),
            otherLength: namesInLabel.length - (FULL_NAME_THRESHOLD - 1),
            mergedStatus: status,
            mergedStatusLabel: statusLabel,
            requestStatus: requestStatusLabel,
            workOrderStatus: workOrderStatusLabel,
            statusType: workOrderStatus ? 'workOrder' : 'request',
          }
        )}
      </Text>
    </Flex>
  );
};
