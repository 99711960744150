import { Text } from '@chakra-ui/react';

type MultiDaysGroupLabelProps = {
  label: string;
};

const MultiDaysGroupLabel = ({ label }: MultiDaysGroupLabelProps) => {
  return (
    <Text
      height={'100%'}
      pl={1}
      pt={1}
      lineHeight={'base'}
      backgroundColor={'neutral.50'}
      color={'text.primary'}
      textOverflow={'ellipsis'}
      overflow={'hidden'}
      whiteSpace={'nowrap'}
      fontSize={'sm'}
    >
      {label}
    </Text>
  );
};

export default MultiDaysGroupLabel;
