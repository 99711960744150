import AccordionListWrapper from '@/common/components/AccordionListWrapper';
import WorkOrderCardList from '@/components/workOrders/WorkOrderCardList';
import { WorkOrderDetailSideDrawerProps } from '@/components/workOrders/v2/WorkOrderDetailSideDrawer';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { Box } from '@chakra-ui/react';
import { WorkOrderCard_WorkOrderFragment } from '../../../WorkOrderCard/index.generated';

export type WorkOrderDailyCardListGroup = {
  id: string;
  label: string;
  workOrders: WorkOrderCard_WorkOrderFragment[];
};

type WorkOrderDailyCardListProps = {
  groups: WorkOrderDailyCardListGroup[];
  openDetail: (props: WorkOrderDetailSideDrawerProps) => void;
  targetGroup: string | null;
};

const WorkOrderDailyCardList = ({
  groups,
  openDetail,
  targetGroup,
}: WorkOrderDailyCardListProps) => {
  const { t_ns } = useTranslation(TASK);
  return (
    <Box overflowY='scroll'>
      {groups.map(({ id, label, workOrders }) => (
        <AccordionListWrapper
          key={id}
          title={label}
          accordionIndexKey={`group-${id}-accordion-index`}
          defaultOpen={targetGroup === id}
        >
          <WorkOrderCardList
            workOrders={workOrders}
            listEmptyText={t_ns('no-tasks')}
            workOrderActions={{}}
            onWorkOrderClicked={(workOrderId: number) => openDetail({ workOrderId })}
          />
        </AccordionListWrapper>
      ))}
    </Box>
  );
};

export default WorkOrderDailyCardList;
