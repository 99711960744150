import type { Query } from '@/graphql/types';
import { type ApolloClient, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

export const useRefetchProductList = (): (() => void) => {
  const client = useApolloClient();

  return useCallback(() => {
    evictQuery(client, 'hierarchicalProducts');
    evictQuery(client, 'products');
  }, [client]);
};

export const useRefetchRequestsList = (): (() => void) => {
  const client = useApolloClient();

  return useCallback(() => {
    evictQuery(client, 'requests');
  }, [client]);
};

export const useRefetchNotifications = (): (() => void) => {
  const client = useApolloClient();

  return useCallback(() => {
    evictQuery(client, 'notifications');
  }, [client]);
};

export const useRefetchCheckTemplatesList = (): (() => void) => {
  const client = useApolloClient();

  return useCallback(() => {
    evictQuery(client, 'checkListTemplatesV2');
  }, [client]);
};

export const useRefetchReportTemplateList = (): (() => void) => {
  const client = useApolloClient();

  return useCallback(() => {
    evictQuery(client, 'reportTemplates');
    evictQuery(client, 'reportTemplate');
  }, [client]);
};

export const useRefetchReport = (): ((id: number) => void) => {
  const client = useApolloClient();

  return useCallback(
    (id: number) => {
      client.cache.evict({ id: `Report:${id}` });
    },
    [client]
  );
};

export const useRefetchWorkflowExecution = (): ((id: number) => void) => {
  const client = useApolloClient();

  return useCallback(
    (id: number) => {
      client.cache.evict({ id: `WorkflowExecution:${id}` });
      evictQuery(client, 'countMyPendingWorkflowExecutions');
    },
    [client]
  );
};

export const useRefetchWorkflowExecutionList = (): (() => void) => {
  const client = useApolloClient();

  return useCallback(() => {
    evictQuery(client, 'countMyPendingWorkflowExecutions');
    evictQuery(client, 'workflowExecutions');
  }, [client]);
};

export const useRefetchWorkOrdersWithPagination = (): (() => void) => {
  const client = useApolloClient();

  return useCallback(() => {
    evictQuery(client, 'workOrdersWithPagination');
  }, [client]);
};

export const useRefetchReportList = (): (() => void) => {
  const client = useApolloClient();

  return useCallback(() => {
    evictQuery(client, 'reports');
  }, [client]);
};

export const useRefetchPivotDataV2 = (): (() => void) => {
  const client = useApolloClient();

  return useCallback(() => {
    evictQuery(client, 'pivotDataV2');
  }, [client]);
};

export const useRefetchCheckLists = (): (() => void) => {
  const client = useApolloClient();

  return useCallback(() => {
    evictQuery(client, 'pivotDataV2');
  }, [client]);
};

const evictQuery = (client: ApolloClient<object>, query: keyof Query) => {
  client.cache.evict({
    id: 'ROOT_QUERY',
    fieldName: query,
  });
};
