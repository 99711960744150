import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
} from '@chakra-ui/react';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

type AccordionListWrapperProps = PropsWithChildren & {
  title: string;
  accordionIndexKey: string;
  defaultOpen?: boolean;
};

const AccordionListWrapper: FC<AccordionListWrapperProps> = ({
  title,
  accordionIndexKey,
  children,
  defaultOpen,
}) => {
  const [accordionIndex, setAccordionIndex] = useLocalStorage<number>(accordionIndexKey, 0);

  useEffect(() => {
    // NOTE: 本コンポーネントではAccordionの下に1つのAccordionItemしかない
    if (defaultOpen === true) {
      setAccordionIndex(0);
    } else if (defaultOpen === false) {
      setAccordionIndex(-1);
    }
  }, [defaultOpen, setAccordionIndex]);

  const onAccordionChange = (index: number | number[]) => {
    Array.isArray(index) ? setAccordionIndex(index[0]) : setAccordionIndex(index);
  };

  return (
    <Accordion allowToggle index={accordionIndex} onChange={onAccordionChange}>
      <AccordionItem>
        <AccordionButton
          backgroundColor='neutral.100'
          paddingTop={1}
          paddingBottom={1}
          paddingInlineEnd={2}
          paddingInlineStart={2}
        >
          <AccordionIcon color='neutral.500' />
          <Heading as='h4' textAlign='left' size='xs' color='neutral.500' fontWeight={500}>
            {title}
          </Heading>
        </AccordionButton>
        <AccordionPanel p={0}>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
export default AccordionListWrapper;
