import CalendarView, {
  CalendarType,
  getCalendarDaysInView,
} from '@/common/components/CalendarView/CalendarView';
import { MultiDaysViewDayGroup } from '@/common/components/CalendarView/internal/common/MultiDaysView/MultiDaysView';
import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { LocalDate } from '@/common/graphql/scalars';
import { useWorkOrderDetailSideDrawer } from '@/components/workOrders/v2/WorkOrderDetailSideDrawer';
import {
  PivotGroupInput,
  PivotGroupType,
  PivotValueType,
  WorkOrderFilterInput,
  WorkOrderFilterType,
} from '@/graphql/types';
import { formatDateToYYYYMMDDForInput } from '@/utils/date/date';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { gql } from '@apollo/client';
import { Box, Flex, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import WorkOrderEventChip from '../WorkOrderEventChip/WorkOrderEventChip';
import {
  useWorkOrderCalendarView_PivotDataV2ForPcSuspenseQuery,
  useWorkOrderCalendarView_PivotDataV2ForSpCalendarSuspenseQuery,
  useWorkOrderCalendarView_PivotDataV2ForSpCardListQuery,
} from './WorkOrderCalendarView.generated';
import WorkOrderDailyCardList, {
  WorkOrderDailyCardListGroup,
} from './internal/WorkOrderDailyCardList/WorkOrderDailyCardList';

type WorkOrderCalendarViewProps = {
  selectedGroupIds: number[];
  selectedAssigneeIds: string[];
};

const WorkOrderGroupType = {
  GROUP: 'GROUP',
  USER: 'USER',
} as const;
type WorkOrderGroupType = (typeof WorkOrderGroupType)[keyof typeof WorkOrderGroupType];

gql`
query WorkOrderCalendarView_PivotDataV2ForSPCalendar(
  $filterSpecs: [WorkOrderFilterInput!]!
    $rowSpecs: [PivotGroupInput!]!
    $columnSpecs: [PivotGroupInput!]!
    $valueSpecs: [PivotValueSpecInput!]!){
    pivotDataV2(
      filterSpecs: $filterSpecs
      rowSpecs: $rowSpecs
      columnSpecs: $columnSpecs
      valueSpecs: $valueSpecs
    )  {
    series {
      values {
        rowValues
        columnValues
        value
      }
    }
    }
    }

    query WorkOrderCalendarView_PivotDataV2ForSPCardList(
  $filterSpecs: [WorkOrderFilterInput!]!
    $rowSpecs: [PivotGroupInput!]!
    $columnSpecs: [PivotGroupInput!]!
    $valueSpecs: [PivotValueSpecInput!]!){
    pivotDataV2(
      filterSpecs: $filterSpecs
      rowSpecs: $rowSpecs
      columnSpecs: $columnSpecs
      valueSpecs: $valueSpecs
    )  {
    series {
      values {
        rowValues
        columnValues
        workOrders {
          ...WorkOrderCard_WorkOrder
        }
      }
    }
    }
    }

    fragment WorkOrderCalendarView_WorkOrder on WorkOrder {
      ...WorkOrderEventChip_WorkOrder
      dueDate
    }
    
    query WorkOrderCalendarView_PivotDataV2ForPC(
  $filterSpecs: [WorkOrderFilterInput!]!
    $rowSpecs: [PivotGroupInput!]!
    $columnSpecs: [PivotGroupInput!]!
    $valueSpecs: [PivotValueSpecInput!]!){
    pivotDataV2(
      filterSpecs: $filterSpecs
      rowSpecs: $rowSpecs
      columnSpecs: $columnSpecs
      valueSpecs: $valueSpecs
    )  {
    series {
      values {
        rowValues
        columnValues
        workOrders {
          ...WorkOrderCalendarView_WorkOrder
        }
      }
    }
    }
    }
`;

const getTargetPivotGroupInput = (groupType: WorkOrderGroupType): PivotGroupInput[] => {
  const ret: PivotGroupInput[] = [];
  if (groupType === WorkOrderGroupType.GROUP) {
    ret.push({
      type: PivotGroupType.Group,
    });
  } else if (groupType === WorkOrderGroupType.USER) {
    ret.push({
      type: PivotGroupType.Assignee,
    });
  }
  return ret;
};

const getCalendarFilterSpec = (
  calendarType: CalendarType,
  startDateOfView: Date,
  selectedGroupIds: number[],
  selectedAssigneeIds: string[]
): WorkOrderFilterInput[] => {
  const endOfWeek = dayjs(startDateOfView)
    .add(getCalendarDaysInView(calendarType) - 1, 'day')
    .toDate();
  const ret: WorkOrderFilterInput[] = [
    {
      type: WorkOrderFilterType.DueDate,
      dueDate: {
        from: formatDateToYYYYMMDDForInput(startDateOfView) as LocalDate,
        to: formatDateToYYYYMMDDForInput(endOfWeek) as LocalDate,
      },
    },
  ];

  if (selectedGroupIds.length > 0) {
    ret.push({
      type: WorkOrderFilterType.Group,
      group: {
        ids: selectedGroupIds,
      },
    });
  }

  if (selectedAssigneeIds.length > 0) {
    ret.push({
      type: WorkOrderFilterType.Assignee,
      assignee: {
        ids: selectedAssigneeIds,
      },
    });
  }

  return ret;
};

const getCalendarFilterSpecForSpCard = (
  targetDate: Date,
  selectedGroupIds: number[],
  _selectedAssigneeIds: string[]
): WorkOrderFilterInput[] => {
  const ret: WorkOrderFilterInput[] = [
    {
      type: WorkOrderFilterType.DueDate,
      dueDate: {
        from: formatDateToYYYYMMDDForInput(targetDate) as LocalDate,
        to: formatDateToYYYYMMDDForInput(targetDate) as LocalDate,
      },
    },
  ];

  if (selectedGroupIds.length > 0) {
    ret.push({
      type: WorkOrderFilterType.Group,
      group: {
        ids: selectedGroupIds,
      },
    });
  }

  return ret;
};

const WorkOrderCalendarView = (props: WorkOrderCalendarViewProps) => {
  return (
    <SuspenseWithSpinner>
      <InternalWorkOrderCalendarView {...props} />
    </SuspenseWithSpinner>
  );
};

const InternalWorkOrderCalendarView = ({
  selectedGroupIds,
  selectedAssigneeIds,
}: WorkOrderCalendarViewProps) => {
  const { t } = useTranslation();

  const workOrderGroupTypeLabelRecord = {
    [WorkOrderGroupType.GROUP]: t('pages.group'),
    [WorkOrderGroupType.USER]: t('pages.user'),
  } satisfies Record<WorkOrderGroupType, string>;

  const [groupType, setGroupType] = useLocalStorage<WorkOrderGroupType>(
    'work-order-calendar-group-type',
    WorkOrderGroupType.GROUP
  );
  const [calendarType, setCalendarType] = useLocalStorage<CalendarType>(
    'work-order-calendar-type',
    CalendarType.WEEK
  );
  const [startDateOfView, setStartDateOfView] = useState(dayjs().toDate());
  const [targetDate, setTargetDate] = useState(dayjs().toDate());
  const [targetGroup, setTargetGroup] = useState<string | null>(null);

  const { isMobile, isDesktop } = useScreenInfos();
  const { component: detailComponent, open: openDetail } = useWorkOrderDetailSideDrawer();

  const { data: workOrderForPC } = useWorkOrderCalendarView_PivotDataV2ForPcSuspenseQuery({
    variables: {
      filterSpecs: getCalendarFilterSpec(
        calendarType,
        startDateOfView,
        selectedGroupIds,
        selectedAssigneeIds
      ),
      rowSpecs: getTargetPivotGroupInput(groupType),
      columnSpecs: [],
      valueSpecs: [
        {
          type: PivotValueType.TaskList,
        },
      ],
    },
    skip: isMobile,
  });

  const { data: workOrderForSPCalendar } =
    useWorkOrderCalendarView_PivotDataV2ForSpCalendarSuspenseQuery({
      variables: {
        filterSpecs: getCalendarFilterSpec(
          calendarType,
          startDateOfView,
          selectedGroupIds,
          selectedAssigneeIds
        ),
        rowSpecs: [
          {
            type: PivotGroupType.DueDate,
          },
          ...getTargetPivotGroupInput(groupType),
        ],
        columnSpecs: [],
        valueSpecs: [
          {
            type: PivotValueType.TaskCount,
          },
        ],
      },
      skip: isDesktop,
    });

  const { data: workOrderForSPCardList } = useWorkOrderCalendarView_PivotDataV2ForSpCardListQuery({
    variables: {
      filterSpecs: getCalendarFilterSpecForSpCard(
        targetDate,
        selectedGroupIds,
        selectedAssigneeIds
      ),
      rowSpecs: getTargetPivotGroupInput(groupType),
      columnSpecs: [],
      valueSpecs: [
        {
          type: PivotValueType.TaskList,
        },
      ],
    },
    skip: isDesktop,
  });
  const valuesByGroup = (workOrderForSPCalendar?.pivotDataV2.series[0]?.values ?? []).reduce(
    (acc, value) => {
      const selectedGroupLabel = value.rowValues[1];
      const dueDateLabel = value.rowValues[0];
      if (!acc[selectedGroupLabel]) {
        acc[selectedGroupLabel] = [];
      }
      acc[selectedGroupLabel].push({
        dueDateLabel,
        count: value.value ?? 0,
      });
      return acc;
    },
    {} as Record<string, { dueDateLabel: string; count: number }[]>
  );

  const groupsForSPCalendar: MultiDaysViewDayGroup[] = Object.entries(valuesByGroup).map(
    ([groupLabel, values]) => {
      return {
        id: groupLabel,
        label: groupLabel,
        events: [],
        counts: Array.from({ length: getCalendarDaysInView(calendarType) }, (_, i) =>
          dayjs(startDateOfView).add(i, 'day').toDate()
        ).map((date) => {
          const targetValue = values.find(
            (value) => value.dueDateLabel === formatDateToYYYYMMDDForInput(date)
          );
          return {
            date,
            eventCount: targetValue?.count ?? 0,
          };
        }),
      };
    }
  );

  const groupsForPCCalendar: MultiDaysViewDayGroup[] = (
    workOrderForPC?.pivotDataV2.series[0]?.values ?? []
  ).map((value) => {
    const selectedGroupLabel = value.rowValues[0];
    return {
      id: selectedGroupLabel,
      label: selectedGroupLabel,
      events: (value.workOrders ?? []).map((workOrder) => ({
        id: workOrder.id,
        renderChip: () => (
          <WorkOrderEventChip
            workOrder={workOrder}
            onClick={() => openDetail({ workOrderId: workOrder.id })}
          />
        ),
        startDate: new Date(workOrder.dueDate),
      })),
      counts: [],
    };
  });
  const groupsWithValuesForCalendar: MultiDaysViewDayGroup[] = isMobile
    ? groupsForSPCalendar
    : groupsForPCCalendar;

  const groupsWithValuesForCardList: WorkOrderDailyCardListGroup[] = (
    workOrderForSPCardList?.pivotDataV2.series[0]?.values ?? []
  ).map((value) => {
    const selectedGroupLabel = value.rowValues[0];
    return {
      id: selectedGroupLabel,
      label: selectedGroupLabel,
      workOrders: value.workOrders ?? [],
    };
  });

  return (
    <Flex
      direction='column'
      w='100%'
      height={'100%'}
      scrollSnapType={{ base: 'y mandatory', md: 'none' }}
      overflow={'scroll'}
    >
      <Box
        height={{ base: '300px', md: '100%' }}
        w='100%'
        scrollSnapAlign={'start'}
        scrollSnapStop={'always'}
      >
        <CalendarView<WorkOrderGroupType>
          groups={groupsWithValuesForCalendar}
          groupTypeSelectProps={{
            options: Object.keys(WorkOrderGroupType) as WorkOrderGroupType[],
            value: groupType,
            onChange: setGroupType,
            getLabel: (key: WorkOrderGroupType) => workOrderGroupTypeLabelRecord[key],
          }}
          startDateOfView={startDateOfView}
          setStartDateOfView={setStartDateOfView}
          calendarType={calendarType}
          setCalendarType={setCalendarType}
          targetDate={targetDate}
          setTargetDate={setTargetDate}
          targetGroup={targetGroup}
          setTargetGroup={setTargetGroup}
        />
      </Box>
      {isMobile && (
        <>
          <Box
            scrollSnapAlign={'start'}
            scrollSnapStop={'always'}
            position={'sticky'}
            top={0}
            // NOTE: タスクカードの担当者アイコンが透けて見えるのを防ぐ
            zIndex={1}
          >
            <Text
              lineHeight={'shorter'}
              color={'neutral.0'}
              backgroundColor={'neutral.500'}
              px={2}
              py={1}
            >
              {dayjs(targetDate).format('YYYY/MM/DD')}
            </Text>
          </Box>
          <Box scrollSnapAlign={'start'} scrollSnapStop={'always'}>
            <WorkOrderDailyCardList
              groups={groupsWithValuesForCardList}
              openDetail={openDetail}
              targetGroup={targetGroup}
            />
          </Box>
        </>
      )}
      {detailComponent}
    </Flex>
  );
};

export default WorkOrderCalendarView;
