import { FileContentType, FileIdAndUrl, IFile } from '@/utils/file/type';
import useTranslation from '@/utils/i18n/useTranslation';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { MdOutlineImage } from 'react-icons/md';
import { AcceptType, FileUploadZone } from '../../FileUploader';
import ToolbarButton from './ToolbarButton';

type MediaAttachmentToolbarButtonType = {
  isEditable: boolean;
  getFileUploadUrls: (filesContentTypes: FileContentType[]) => Promise<FileIdAndUrl[]>;
  onFilesAdded: (files: IFile[]) => void;
};

const MediaAttachmentToolbarButton = (props: MediaAttachmentToolbarButtonType) => {
  const { isEditable, getFileUploadUrls, onFilesAdded } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const ACCEPT_TYPES: AcceptType[] = ['image', 'video', 'pdf'];

  const onInsertAttachmentClick = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const onAddFiles = (files: IFile[]) => {
    const localFiles = files.map(({ id, file, fileData }) => ({
      id,
      fileData,
      file,
    }));
    onFilesAdded(localFiles);
    onClose();
  };

  return (
    <>
      <ToolbarButton
        icon={<MdOutlineImage />}
        isDisabled={!isEditable}
        ariaLabel='Insert media attachment'
        onClick={onInsertAttachmentClick}
      />
      <Modal isOpen={isOpen} onClose={onClose} size='lg'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('actions.insert-media')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FileUploadZone
              addFiles={onAddFiles}
              getFileUploadUrls={getFileUploadUrls}
              acceptTypes={ACCEPT_TYPES}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='primary' mr={3} onClick={onClose}>
              {t('actions.cancel')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default MediaAttachmentToolbarButton;
