import { WorkOrderFormDefaultValuesType } from '@/common/hooks/useCreateWorkOrder';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { Box, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import {
  MdFileDownload,
  MdFilterListAlt,
  MdMoreVert,
  MdOutlineInsertDriveFile,
  MdQrCodeScanner,
} from 'react-icons/md';

import DeviceAdaptiveActionButton from '@/common/components/DeviceAdaptiveActionButton';
import DropDownMenuWrapper from '@/common/components/DropDownMenuWrapper';
import Link from '@/common/components/Link';
import PageTitle from '@/common/components/PageTitle';
import PopoverMessageWrapper from '@/common/components/PopoverMessageWrapper';
import { useApplicationContext } from '@/context/ApplicationContext';
import { useTemplateContext } from '@/context/TemplateContext';
import QrScannerModalContent from '@/modules/assets/components/QrScannerModalContent';
import VoiceControlModal from '@/modules/assets/components/VoiceControlModal';
import WorkOrderViewToggle, {
  WorkOrderViewToggleProps,
  WorkOrderViewType,
} from '@/modules/workOrders/components/WorkOrderViewToggle/WorkOrderViewToggle';
import { IWorkOrderTemplateBase } from '@/modules/workOrders/types/workOrderTemplate';
import { useModal } from '@/utils/hooks/useModal';
import { TASK } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { Dispatch, SetStateAction } from 'react';

type WorkOrderToolbarProps = {
  accessAiVoice: boolean;
  showMobileDetail: boolean;
  workOrderTemplate: IWorkOrderTemplateBase;
  workOrderCreateButtonClicked: (assetId?: number, templateId?: number) => void;
  onTemplateSettingsClick?: () => void;
  onAiVoiceSubmit: (preWorkOrderData: WorkOrderFormDefaultValuesType) => void;
  setIsSPFilterOpen: Dispatch<SetStateAction<boolean>>;
} & WorkOrderViewToggleProps;

export default function WorkOrderToolbar(props: WorkOrderToolbarProps) {
  const { isDesktop } = useScreenInfos();
  const {
    accessAiVoice,
    showMobileDetail,
    workOrderCreateButtonClicked,
    onTemplateSettingsClick,
    workOrderTemplate,
    onAiVoiceSubmit,
    selectedView,
    setIsSPFilterOpen,
  } = props;

  const { t, t_ns } = useTranslation(TASK);
  const { isAdmin } = useApplicationContext();
  const { defaultWorkOrderTemplate, workOrderTemplates, fetchAndSetDefaultWorkOrderTemplate } =
    useTemplateContext();

  const handleCustomizeTemplateSelect = async (id: number) => {
    await fetchAndSetDefaultWorkOrderTemplate(id);

    onTemplateSettingsClick && onTemplateSettingsClick();
  };

  const workOrderTemplatesForMenuItems = workOrderTemplates.map((template) => ({
    id: template.id,
    name: template.name,
    Icon: MdOutlineInsertDriveFile,
  }));

  const { element: QrScannerModal, open: onQrModalOpen } = useModal({
    Component: QrScannerModalContent,
  });

  return (
    <PageTitle
      title={t('pages.task')}
      titleSize='md'
      borderBottom='0px'
      hidden={showMobileDetail}
      rightChild={<WorkOrderViewToggle selectedView={selectedView} />}
    >
      <HStack spacing='5px'>
        <DeviceAdaptiveActionButton
          text={t_ns('new-task')}
          onClick={() => workOrderCreateButtonClicked()}
        />
        <IconButton
          size='sm'
          variant='ghost'
          fontSize='2xl'
          aria-label='Scan asset qr code'
          onClick={() =>
            onQrModalOpen({
              workOrderCreateButtonClicked,
            })
          }
          icon={<MdQrCodeScanner />}
        />
        {QrScannerModal}

        {accessAiVoice && (
          <VoiceControlModal onAiVoiceSubmit={onAiVoiceSubmit} template={workOrderTemplate} />
        )}
        {!isDesktop && selectedView === WorkOrderViewType.CALENDAR && (
          <IconButton
            size='sm'
            variant='ghost'
            fontSize='2xl'
            aria-label='Filter'
            onClick={() => {
              setIsSPFilterOpen((prev) => !prev);
            }}
            icon={<MdFilterListAlt />}
          />
        )}
        {isDesktop && (
          <Menu>
            <MenuButton
              as={IconButton}
              size='sm'
              mr={2}
              colorScheme='gray'
              variant='ghost'
              fontSize={20}
              aria-label='Asset More Action'
              icon={<MdMoreVert />}
            />
            <MenuList zIndex={101}>
              <PopoverMessageWrapper
                message={t('warning.no-permission.download')}
                isDisabled={!isAdmin}
                placement='bottom-end'
              >
                <Link to='/dashboard/#export'>
                  <MenuItem
                    fontSize='sm'
                    fontWeight='normal'
                    icon={<MdFileDownload />}
                    isDisabled={!isAdmin}
                  >
                    {t('actions.batch-download')}
                  </MenuItem>
                </Link>
              </PopoverMessageWrapper>
              <PopoverMessageWrapper
                isDisabled={!isAdmin}
                message={t('warning.no-permission.edit')}
                placement='bottom-end'
              >
                <DropDownMenuWrapper
                  menuItems={workOrderTemplatesForMenuItems}
                  activeMenuItemId={defaultWorkOrderTemplate?.id}
                  onMenuItemClick={handleCustomizeTemplateSelect}
                  gutter={20}
                  width='full'
                  _hover={{ bgColor: 'gray.100' }}
                  showArrowIcon
                >
                  <MenuItem
                    as={Box}
                    isDisabled={!isAdmin}
                    fontSize='sm'
                    fontWeight='normal'
                    onClick={onTemplateSettingsClick}
                    bgColor='transparent'
                  >
                    {t('actions.customize')}
                  </MenuItem>
                </DropDownMenuWrapper>
              </PopoverMessageWrapper>
            </MenuList>
          </Menu>
        )}
      </HStack>
    </PageTitle>
  );
}
