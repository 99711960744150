import * as Types from '../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PartCustomFieldFragmentFragment = (
  { __typename?: 'PartCustomField' }
  & Pick<Types.PartCustomField, 'id' | 'label' | 'type' | 'defaults'>
  & { customFieldIntConfig?: Types.Maybe<(
    { __typename?: 'PartCustomFieldIntConfig' }
    & Pick<Types.PartCustomFieldIntConfig, 'min' | 'max' | 'interval'>
  )>, customFieldFloatConfig?: Types.Maybe<(
    { __typename?: 'PartCustomFieldFloatConfig' }
    & Pick<Types.PartCustomFieldFloatConfig, 'min' | 'max' | 'interval'>
  )>, customFieldSelectConfig?: Types.Maybe<(
    { __typename?: 'PartCustomFieldSelectConfig' }
    & Pick<Types.PartCustomFieldSelectConfig, 'multiple'>
  )>, options: Array<(
    { __typename?: 'PartCustomFieldOption' }
    & Pick<Types.PartCustomFieldOption, 'id' | 'value'>
  )> }
);

export type PartCustomFieldsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PartCustomFieldsQuery = (
  { __typename?: 'Query' }
  & { partCustomFields: Array<(
    { __typename?: 'PartCustomField' }
    & Pick<Types.PartCustomField, 'id' | 'label' | 'type' | 'defaults'>
    & { customFieldIntConfig?: Types.Maybe<(
      { __typename?: 'PartCustomFieldIntConfig' }
      & Pick<Types.PartCustomFieldIntConfig, 'min' | 'max' | 'interval'>
    )>, customFieldFloatConfig?: Types.Maybe<(
      { __typename?: 'PartCustomFieldFloatConfig' }
      & Pick<Types.PartCustomFieldFloatConfig, 'min' | 'max' | 'interval'>
    )>, customFieldSelectConfig?: Types.Maybe<(
      { __typename?: 'PartCustomFieldSelectConfig' }
      & Pick<Types.PartCustomFieldSelectConfig, 'multiple'>
    )>, options: Array<(
      { __typename?: 'PartCustomFieldOption' }
      & Pick<Types.PartCustomFieldOption, 'id' | 'value'>
    )> }
  )> }
);

export const PartCustomFieldFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PartCustomFieldFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PartCustomField"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"customFieldIntConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"min"}},{"kind":"Field","name":{"kind":"Name","value":"max"}},{"kind":"Field","name":{"kind":"Name","value":"interval"}}]}},{"kind":"Field","name":{"kind":"Name","value":"customFieldFloatConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"min"}},{"kind":"Field","name":{"kind":"Name","value":"max"}},{"kind":"Field","name":{"kind":"Name","value":"interval"}}]}},{"kind":"Field","name":{"kind":"Name","value":"customFieldSelectConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"multiple"}}]}},{"kind":"Field","name":{"kind":"Name","value":"options"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}},{"kind":"Field","name":{"kind":"Name","value":"defaults"}}]}}]} as unknown as DocumentNode;
export const PartCustomFieldsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PartCustomFields"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partCustomFields"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PartCustomFieldFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PartCustomFieldFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PartCustomField"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"customFieldIntConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"min"}},{"kind":"Field","name":{"kind":"Name","value":"max"}},{"kind":"Field","name":{"kind":"Name","value":"interval"}}]}},{"kind":"Field","name":{"kind":"Name","value":"customFieldFloatConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"min"}},{"kind":"Field","name":{"kind":"Name","value":"max"}},{"kind":"Field","name":{"kind":"Name","value":"interval"}}]}},{"kind":"Field","name":{"kind":"Name","value":"customFieldSelectConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"multiple"}}]}},{"kind":"Field","name":{"kind":"Name","value":"options"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}},{"kind":"Field","name":{"kind":"Name","value":"defaults"}}]}}]} as unknown as DocumentNode;

/**
 * __usePartCustomFieldsQuery__
 *
 * To run a query within a React component, call `usePartCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartCustomFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartCustomFieldsQuery(baseOptions?: Apollo.QueryHookOptions<PartCustomFieldsQuery, PartCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartCustomFieldsQuery, PartCustomFieldsQueryVariables>(PartCustomFieldsDocument, options);
      }
export function usePartCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartCustomFieldsQuery, PartCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartCustomFieldsQuery, PartCustomFieldsQueryVariables>(PartCustomFieldsDocument, options);
        }
export function usePartCustomFieldsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PartCustomFieldsQuery, PartCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PartCustomFieldsQuery, PartCustomFieldsQueryVariables>(PartCustomFieldsDocument, options);
        }
export type PartCustomFieldsQueryHookResult = ReturnType<typeof usePartCustomFieldsQuery>;
export type PartCustomFieldsLazyQueryHookResult = ReturnType<typeof usePartCustomFieldsLazyQuery>;
export type PartCustomFieldsSuspenseQueryHookResult = ReturnType<typeof usePartCustomFieldsSuspenseQuery>;
export type PartCustomFieldsQueryResult = Apollo.QueryResult<PartCustomFieldsQuery, PartCustomFieldsQueryVariables>;