import { LinkProps } from '@/common/components/FileUploader';
import { useConfirmModal } from '@/context/ConfirmModalContext';
import { DocumentTypeEnum, NewPartDocumentInput } from '@/graphql/types';
import {
  useRemovePartDocumentMutation,
  useRemovePartFileDataMutation,
} from '@/modules/parts/graphql/parts.generated';
import {
  useAddPartDocumentsMutation,
  useAddPartFilesMutation,
} from '@/modules/parts/hooks/useParts.generated';
import { toastPromiseOptions, useToast } from '@/utils/atoms/toast';
import { IFile } from '@/utils/file/type';
import { ASSETS } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';

gql`
  input NewPartFilesInput {
    partId: Float
    files: [NewPartFileInput!]!
  }

  mutation AddPartFiles($newPartFilesInput: NewPartFilesInput!) {
    addPartFiles(newPartFilesInput: $newPartFilesInput) {
      id
      files {
        partId
        name
        contentType
        fileId
        path
      }
    }
  }

  input NewPartDocumentsInput {
    partId: Float
    documents: [NewPartDocumentInput!]!
  }

  mutation AddPartDocuments($newPartDocumentsInput: NewPartDocumentsInput!) {
    addPartDocuments(newPartDocumentsInput: $newPartDocumentsInput) {
      id
      documents {
        partId
        name
        contentType
        fileId
      }
    }
  }
`;

type ReturnType = {
  createPartFiles: (files: IFile[]) => Promise<void>;
  deletePartFile: (fileId: string) => Promise<void>;
  createPartDocuments: (link?: LinkProps, files?: IFile[]) => Promise<void>;
  deletePartDocument: (id: number, fileId?: string) => Promise<void>;
};

export const useParts = (targetPartId: number | undefined): ReturnType => {
  const { toastPromise } = useToast();
  const { t, t_errors } = useTranslation(ASSETS);
  const { confirm } = useConfirmModal();

  const [addPartFiles] = useAddPartFilesMutation();
  const [addPartDocuments] = useAddPartDocumentsMutation();
  const [removePartDocument] = useRemovePartDocumentMutation();
  const [removePartFileData] = useRemovePartFileDataMutation();

  const createPartFiles = async (files: IFile[]) => {
    if (!targetPartId) throw new Error(t_errors('parts.part-does-not-exist'));

    const fileContents = files.map(({ id, file: { name, type } }) => ({
      fileId: id,
      name,
      contentType: type,
    }));

    await toastPromise(
      async () => {
        await addPartFiles({
          variables: {
            newPartFilesInput: {
              partId: targetPartId,
              files: fileContents,
            },
          },
        });
      },
      toastPromiseOptions(t, 'create')
    );
  };

  const deletePartFile = async (fileId: string) => {
    if (targetPartId === undefined) throw Error(t_errors('parts.part-does-not-exist'));
    const result = await confirm(
      t('confirmation.delete-file'),
      t('warning.delete'),
      t('confirmation.delete')
    );
    if (!result) return;

    await removePartFileData({
      variables: {
        deletePartFileInput: {
          fileId,
          partId: targetPartId,
        },
      },
    });
  };

  const createPartDocuments = async (link?: LinkProps, files?: IFile[]) => {
    if (targetPartId === undefined) throw Error(t_errors('parts.part-does-not-exist'));

    const documentsInput: NewPartDocumentInput[] = (() => {
      if (files) {
        return files.map(({ id, file, size }) => ({
          fileId: id,
          name: file.name,
          contentType: file.type,
          size,
          type: DocumentTypeEnum.Document,
        }));
      } else if (link) {
        return [
          {
            name: link.name,
            url: link.url,
            type: DocumentTypeEnum.Link,
          },
        ];
      } else {
        throw new Error(t_errors('file-or-link-required'));
      }
    })();

    await toastPromise(
      async () => {
        await addPartDocuments({
          variables: {
            newPartDocumentsInput: {
              partId: targetPartId,
              documents: documentsInput,
            },
          },
        });
      },
      toastPromiseOptions(t, 'create')
    );
  };

  const deletePartDocument = async (id: number, fileId?: string) => {
    if (targetPartId === undefined) throw Error(t_errors('parts.part-does-not-exist'));
    const result = await confirm(
      t('confirmation.delete-file'),
      t('warning.delete'),
      t('confirmation.delete')
    );
    if (!result) return;

    await removePartDocument({
      variables: {
        deletePartDocument: {
          id,
          partId: targetPartId,
          fileId,
        },
      },
    });
  };

  return {
    createPartFiles,
    deletePartFile,
    createPartDocuments,
    deletePartDocument,
  };
};
