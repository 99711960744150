import { UpdateWorkOrderInput } from '@/graphql/types';

import DetailDisplayItem from '@/common/components/DetailDisplayItem';
import DisplayDownTime from '@/common/components/DisplayDownTime';
import { useApplicationContext } from '@/context/ApplicationContext';
import { useRefetchPivotDataV2 } from '@/lib/useApolloRefetchQueries';
import { useUpdateWorkOrderMutation } from '@/modules/workOrders/graphql/workOrders.generated';
import { formatDateToMDHHmm_or_YYYYMDHHmm, formatDateToMD_or_YYYYMD } from '@/utils/date/date';
import { gql } from '@apollo/client';
import { Box, Heading, VStack, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import WorkOrderPriorityLabel from '../WorkOrderPriorityLabel';
import WorkOrderStatusRadio from '../WorkOrderStatusRadio';
import { AssetDisplay } from './AssetDispplay';
import { CheckListDisplay } from './CheckListDisplay';
import { CustomFieldValueDisplay } from './CustomFieldValueDisplay';
import { ProductDisplay } from './ProductDisplay';
import { useWorkOrderV2SuspenseQuery } from './WorkOrderDetailV2.generated';
import { WorkOrderPartsDisplay } from './WorkOrderPartDisplay';

gql`
  fragment WorkOrderDetailV2_WorkOrder on WorkOrder {
    id
    title
    status
    priority
    description
    dueDate
    stoppage {
      startAt
      endAt
    }
    assignees {
      name
    }
    asset {
      id
    }
    template {
      fieldOrders {
        id
        order
        type
        customFieldId
      }
    }
    createdAt
    createdBy {
      name
    }
    updatedAt
    updatedBy {
      name
    }
  }
`;

gql`
  query WorkOrderV2($id: Float!) {
    workOrder(id: $id) {
      ...WorkOrderDetailV2_WorkOrder
      asset {
        ...AssetDisplay_Asset
      }
      ...CustomFieldValueDisplay_WorkOrder
      parts {
        ...WorkOrderPartsDisplay_WorkOrderPart
      }
      product {
        ...ProductDisplay_Product
      }
      template {
        customFields {
          ...CustomFieldValueDisplay_WorkOrderCustomField
        }
      }
      checkLists {
        ...CheckListDisplay_CheckList
      }
    }
  }
`;

type Props = {
  workOrderId: number;
};

export const WorkOrderDetailV2 = ({ workOrderId }: Props) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { companySetting } = useApplicationContext();

  const {
    data: { workOrder: order },
  } = useWorkOrderV2SuspenseQuery({
    variables: { id: workOrderId },
    fetchPolicy: 'cache-and-network',
  });

  const [updateWorkOrderMutation] = useUpdateWorkOrderMutation();
  const refetchPivotDataV2 = useRefetchPivotDataV2();

  const updateWorkOrder = (input: Omit<UpdateWorkOrderInput, 'id'>) => {
    toast.promise(
      updateWorkOrderMutation({
        variables: { updateWorkOrderInput: { ...input, id: workOrderId } },
        onCompleted: () => {
          refetchPivotDataV2();
        },
      }),
      {
        success: { title: t('success.task.update', { ns: 'toasts' }) },
        error: { title: t('failed.task.update-task-failed', { ns: 'toasts' }) },
        loading: { title: t('in-progress.task.update', { ns: 'toasts' }) },
      }
    );
  };

  if (!companySetting) return null;
  const { accessProduct } = companySetting;
  const {
    title,
    status,
    priority,
    stoppage,
    description,
    assignees,
    asset,
    dueDate,
    product,
    parts,
    checkLists,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
  } = order;

  return (
    <Box p={2}>
      <Heading size='md' my={4} mx={2}>
        {title}
      </Heading>
      <VStack alignItems='stretch' my={4} mx={2}>
        <WorkOrderStatusRadio
          status={status}
          updateWorkOrderStatus={(status) =>
            updateWorkOrder({
              status,
            })
          }
        />

        <Box bg='neutral.0' borderRadius='md' px={4} py={2} my={4}>
          {[...order.template.fieldOrders]
            .sort((a, b) => a.order - b.order)
            .map((fieldOrder) => (
              <Box key={fieldOrder.id}>
                {fieldOrder.type === 'priority' && priority !== 'none' && (
                  <DetailDisplayItem
                    label={t('priority.title')}
                    data-testid='work-order-detail-priority'
                  >
                    <WorkOrderPriorityLabel priority={priority} />
                  </DetailDisplayItem>
                )}
                {fieldOrder.type === 'description' && (
                  <DetailDisplayItem
                    label={t('description')}
                    data-testid='work-order-detail-description'
                    value={description}
                    vertical={true}
                  />
                )}
                {fieldOrder.type === 'stoppage' && stoppage && (
                  <DisplayDownTime startAt={stoppage.startAt} endAt={stoppage.endAt} />
                )}
                {fieldOrder.type === 'assignee' && (
                  <DetailDisplayItem
                    label={t('assignee')}
                    data-testid='work-order-detail-assignee'
                    value={assignees.map((assignee) => assignee.name).join(', ')}
                  />
                )}

                {fieldOrder.type === 'asset' && asset && (
                  <DetailDisplayItem data-testid='work-order-detail-asset' label={t('pages.asset')}>
                    <AssetDisplay asset={asset} />
                  </DetailDisplayItem>
                )}

                {fieldOrder.type === 'due' && (
                  <DetailDisplayItem
                    data-testid='work-order-detail-due-date'
                    label={t('date.due-date')}
                    value={formatDateToMD_or_YYYYMD(dueDate)}
                  />
                )}
                {accessProduct && fieldOrder.type === 'product' && product && (
                  <DetailDisplayItem
                    data-testid='work-order-detail-product'
                    label={t('pages.product')}
                  >
                    <ProductDisplay product={product} />
                  </DetailDisplayItem>
                )}

                {fieldOrder.type === 'part' && parts.length > 0 && (
                  <Box my={2}>
                    <Heading size='sm' my={4} mx={2}>
                      {t('pages.parts')}
                    </Heading>
                    {parts.map((part) => (
                      <WorkOrderPartsDisplay key={part.part.id} workOrderPart={part} />
                    ))}
                  </Box>
                )}

                {fieldOrder.type === 'customField' && fieldOrder.customFieldId && (
                  <CustomFieldValueDisplay
                    customFieldId={fieldOrder.customFieldId}
                    values={order}
                    customFields={order.template.customFields}
                  />
                )}
                {fieldOrder.type === 'checkList' &&
                  checkLists.map((checkList) => (
                    <Box
                      key={checkList.id}
                      borderRadius='md'
                      m={2}
                      p={2}
                      border='1px'
                      borderColor='neutral.200'
                    >
                      <CheckListDisplay checkList={checkList} />
                    </Box>
                  ))}
              </Box>
            ))}

          <DetailDisplayItem
            label={t('date.creator-date')}
            data-testid='work-order-detail-creator-date'
            value={`${createdBy.name}、${formatDateToMDHHmm_or_YYYYMDHHmm(createdAt)}`}
          />
          {createdAt !== updatedAt && (
            <DetailDisplayItem
              label={t('date.last-updater-date')}
              data-testid='work-order-detail-last-updater-date'
              value={`${updatedBy.name}、${formatDateToMDHHmm_or_YYYYMDHHmm(updatedAt)}`}
            />
          )}
        </Box>
      </VStack>
    </Box>
  );
};
