import { useDrawer } from '@/utils/hooks/useDrawer';
import { useModal } from '@/utils/hooks/useModal';

import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import {
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useModalContext,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = {
  templates: { id: number; name: string }[];
  onSelect: (id: number) => void;
};

export const useSelectWorkOrderTemplate = ({ templates, onSelect }: Props) => {
  const { isMobile } = useScreenInfos();
  const { element: mobileElement, open: openMobile } = useDrawer({
    Component: SelectWorkOrderTemplateBottomDrawer,
    drawerProps: {
      placement: 'bottom',
      size: 'lg',
    },
  });

  const { element: browserElement, open: openBrowser } = useModal({
    Component: SelectWorkOrderTemplateModal,
  });

  const onSelectTemplate = (id: number) => {
    onSelect(id);
  };

  const open = () => {
    if (isMobile) {
      openMobile({ templates, onSelect: onSelectTemplate });
      return;
    }
    openBrowser({ templates, onSelect: onSelectTemplate });
  };

  return {
    element: isMobile ? mobileElement : browserElement,
    open: open,
  };
};

const SelectWorkOrderTemplateBottomDrawer = ({ templates, onSelect }: Props) => {
  const { t } = useTranslation();
  // 本来ならDrawerContextを使うべきだが、DrawerContextが未実装のため、ModalContextを使っている
  const drawerContext = useModalContext();

  return (
    <DrawerContent>
      <DrawerHeader fontWeight='semibold' borderBottom='1px solid' borderColor='neutral.200'>
        {t('actions.select-template')}
      </DrawerHeader>
      <DrawerCloseButton top={4} />
      <DrawerBody px={2}>
        <Flex direction='column' gap={2} py={2}>
          {templates.map((template) => (
            <Button
              key={template.id}
              border='none'
              width='full'
              variant='outline'
              onClick={() => {
                drawerContext.onClose();
                onSelect(template.id);
              }}
              py={3}
              justifyContent='start'
            >
              {template.name}
            </Button>
          ))}
        </Flex>
      </DrawerBody>
    </DrawerContent>
  );
};

const SelectWorkOrderTemplateModal = ({ templates, onSelect }: Props) => {
  const modalContext = useModalContext();
  const { t } = useTranslation();

  return (
    <ModalContent>
      <ModalHeader fontWeight='semibold'>{t('actions.select-template')}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Flex direction='column' gap={2} py={2}>
          {templates.map((template) => (
            <Button
              key={template.id}
              border='none'
              borderBottom='1px solid'
              borderColor='neutral.200'
              borderRadius={0}
              width='full'
              variant='outline'
              onClick={() => {
                modalContext.onClose();
                onSelect(template.id);
              }}
              py={3}
              justifyContent='start'
            >
              {template.name}
            </Button>
          ))}
        </Flex>
      </ModalBody>

      <ModalFooter>
        <Button
          colorScheme='gray'
          onClick={() => {
            modalContext.onClose();
          }}
        >
          {t('actions.cancel')}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
