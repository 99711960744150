import { CustomFieldItem, validateSelectRequired } from '@/common/components/CustomFieldItem';
import DateTimePicker from '@/common/components/DateTimePicker';
import DisplayDiffDownTime from '@/common/components/DisplayDiffDownTime';
import { InputTag, InputTagProps } from '@/common/components/InputTag';
import MultipleLayerSelect from '@/common/components/MultipleLayerSelect';
import PageTitle from '@/common/components/PageTitle';
import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import UserSelectInput from '@/common/components/UserSelectInput';
import { useApplicationContext } from '@/context/ApplicationContext';
import { ApplicationProvider_CompanySettingFragment } from '@/context/ApplicationContext.generated';
import {
  WorkOrderPriority,
  WorkOrderStatus,
  WorkOrderTemplateFieldOrderType,
} from '@/graphql/types';
import { AssetMultiLayerSelect } from '@/modules/assets/components/AssetMultiLayerSelect';
import { useHierarchicalProducts } from '@/modules/products';
import { uniqBy } from '@/utils/array/array';
import { isFromDateAfterToDate } from '@/utils/date/date';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Spinner,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { lazy } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import WorkOrderPriorityRadio from '../../WorkOrderPriorityRadio';
import { WorkOrderRecentlyUsedValues } from '../../WorkOrderRecentlyUsedValues';
import WorkOrderStatusRadio from '../../WorkOrderStatusRadio';
import { WorkOrderStoppageReasonPicker } from '../../WorkOrderStoppageReasonPicker';
import WorkOrderCheckListField from '../../checkList/WorkOrderCheckListField';
import WorkOrderPartField from '../../parts/WorkOrderPartField';
import { useWorkOrderForm } from './WorkOrderForm.hooks';
import { WorkOrderFormProps, WorkOrderFormType } from './WorkOrderForm.types';
const WorkOrderCheckListCustomFieldList = lazy(
  () => import('../../checkList/WorkOrderCheckListCustomFieldList')
);

const WorkOrderForm = (props: WorkOrderFormProps) => {
  const { companySetting } = useApplicationContext();
  if (!companySetting) return null;
  return <InternalWorkOrderForm companySetting={companySetting} {...props} />;
};

type InternalWorkOrderFormProps = WorkOrderFormProps & {
  companySetting: ApplicationProvider_CompanySettingFragment;
};

export const InternalWorkOrderForm = (props: InternalWorkOrderFormProps) => {
  const {
    handleCancel,
    handlePause,
    customFields,
    onSubmit,
    onError,
    onChangeWorkOrderStatus,
    onChangeCheckListFormValue,
    handleAddCustomFieldFileValues,
    handleRemoveCustomFieldFileValue,
    handleUpdateCustomFieldFileValue,
    errors,
    handleSubmit,
    register,
    formData,
    fieldOrders,
    setCustomFieldTextLocalValues,
    setCustomFieldIntLocalValues,
    setCustomFieldFloatLocalValues,
    setCustomFieldSelectLocalValues,
    setCustomFieldDatetimeLocalValues,
    setCustomFieldDateLocalValues,
    setCustomFieldUserLocalValues,
    customFieldTextLocalValues,
    customFieldIntLocalValues,
    customFieldFloatLocalValues,
    customFieldSelectLocalValues,
    customFieldDatetimeLocalValues,
    customFieldDateLocalValues,
    customFieldUserLocalValues,
    checkListFormValue,
    methods,
    isSubmitting,
    getFileUploadUrls,
    customFieldFileLocalValues,
    status,
    getDefaultFieldLabel,
  } = useWorkOrderForm(props);
  const { selectProductItems } = useHierarchicalProducts();
  const { isMobile, isDesktop } = useScreenInfos();
  const { t, i18n } = useTranslation();
  const selectedAssetId = useWatch<WorkOrderFormType, 'assetId'>({
    control: methods.control,
    name: 'assetId',
  });

  const {
    cancel,
    onUpdatePart,
    workOrder,
    createCheckList,
    deleteCheckList,
    title = t('actions.create-task'),
    submitButton = t('actions.register'),
    usePauseButton = false,
    displayStatus = false,
    checkListTemplate,
    companySetting,
  } = props;
  const { accessCheckList, accessProduct } = companySetting;

  return (
    <Flex direction='column' bg='neutral.50' height='inherit'>
      <PageTitle
        title={title}
        titleSize='sm'
        minH={{ base: 'auto', md: '52px' }}
        maxH={{ base: 'auto', md: '52px' }}
        position='sticky'
        top={0}
        zIndex={10}
        leftChild={
          isMobile && (
            <IconButton
              onClick={() => cancel()}
              aria-label='Back'
              size='md'
              variant='ghost'
              icon={<MdClose />}
            />
          )
        }
      >
        {isMobile && (
          <Flex gap={2}>
            {usePauseButton && (
              <Button color='gray.500' onClick={handlePause} width='60px' variant='ghost' size='sm'>
                {t('actions.pause')}
              </Button>
            )}

            <Button
              colorScheme='primary'
              onClick={handleSubmit(onSubmit, onError)}
              width='60px'
              variant='ghost'
              size='sm'
            >
              {submitButton}
            </Button>
          </Flex>
        )}
      </PageTitle>
      <Box as='form' onSubmit={handleSubmit(onSubmit, onError)} flex='1' overflowY='scroll'>
        {displayStatus && (
          <Box alignItems='flex-start' my={4} mx={2}>
            <WorkOrderStatusRadio status={status} updateWorkOrderStatus={onChangeWorkOrderStatus} />
          </Box>
        )}

        {[...fieldOrders]
          .sort((a, b) => a.order - b.order)
          .map((fieldOrder) => {
            const { requiredOnDone } = fieldOrder;
            const required = requiredOnDone && status === WorkOrderStatus.Done;
            const inputTagProps: InputTagProps | undefined = requiredOnDone
              ? status === WorkOrderStatus.Done
                ? {
                    type: 'required',
                  }
                : {
                    label: t('form.badge.required-on-done'),
                    backgroundColor: 'neutral.200',
                    color: 'neutral.600',
                  }
              : undefined;
            return (
              <Box key={fieldOrder.id} width='100%'>
                {fieldOrder.type === WorkOrderTemplateFieldOrderType.Title && (
                  <FormControl
                    isInvalid={!!errors.title}
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                  >
                    <FormLabel htmlFor='title'>
                      <Flex gap={2} alignItems='center'>
                        {getDefaultFieldLabel('title')}
                        {inputTagProps && <InputTag {...inputTagProps} />}
                      </Flex>
                    </FormLabel>
                    <Input
                      id='title'
                      {...register('title', {
                        required: required ? t('form.validation.required') : false,
                      })}
                    />
                    <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
                  </FormControl>
                )}

                {fieldOrder.type === WorkOrderTemplateFieldOrderType.Description && (
                  <FormControl
                    isInvalid={!!errors.description}
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                  >
                    <FormLabel>
                      <Flex gap={2} alignItems='center'>
                        {getDefaultFieldLabel('description')}
                        {inputTagProps && <InputTag {...inputTagProps} />}
                      </Flex>
                    </FormLabel>
                    <Textarea
                      id='description'
                      {...register('description', {
                        required: required ? t('form.validation.required') : false,
                      })}
                    />
                    <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
                  </FormControl>
                )}

                {fieldOrder.type === WorkOrderTemplateFieldOrderType.Asset && (
                  <Controller
                    control={methods.control}
                    name='assetId'
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <FormControl
                        isInvalid={!!error}
                        bg='neutral.0'
                        borderRadius='md'
                        px={4}
                        py={2}
                        mx={2}
                        my={4}
                        width='auto'
                        data-testid='work-order-form-asset'
                      >
                        <FormLabel>
                          <Flex gap={2} alignItems='center'>
                            {getDefaultFieldLabel('assetId')}
                            {inputTagProps && <InputTag {...inputTagProps} />}
                          </Flex>
                        </FormLabel>
                        <SuspenseWithSpinner>
                          <AssetMultiLayerSelect value={value} onChange={onChange} />
                        </SuspenseWithSpinner>
                        {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
                        <VStack mt={2} alignItems='start' gap={0}>
                          <Heading size='xs'>{t('form.recently-used')}</Heading>

                          <Flex minH='40px'>
                            {!formData ? (
                              <Spinner color='primary.500' size='sm' alignSelf='center' />
                            ) : (
                              <WorkOrderRecentlyUsedValues
                                items={formData.recentlyUsedWorkOrderAssets}
                                onClick={(item) => onChange(item.id)}
                              />
                            )}
                          </Flex>
                        </VStack>
                      </FormControl>
                    )}
                    rules={{
                      required: required ? t('form.validation.required') : false,
                      deps: ['stoppageReasonId'],
                    }}
                  />
                )}
                {fieldOrder.type === WorkOrderTemplateFieldOrderType.Due && (
                  <FormControl
                    isInvalid={!!errors.dueDate}
                    bg='neutral.0'
                    borderRadius='md'
                    px={4}
                    py={2}
                    mx={2}
                    my={4}
                    width='auto'
                  >
                    <FormLabel>
                      <Flex gap={2} alignItems='center'>
                        {getDefaultFieldLabel('dueDate')}
                        {inputTagProps && <InputTag {...inputTagProps} />}
                      </Flex>
                    </FormLabel>
                    <Input
                      id='due-date-at'
                      type='date'
                      {...register('dueDate', {
                        required: required ? t('form.validation.required') : false,
                        setValueAs: (value) => (value === '' ? null : value),
                      })}
                    />
                    <FormErrorMessage>{errors.dueDate?.message}</FormErrorMessage>
                  </FormControl>
                )}

                {accessProduct && fieldOrder.type === WorkOrderTemplateFieldOrderType.Product && (
                  <Controller
                    control={methods.control}
                    name='productId'
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <FormControl
                        isInvalid={!!error}
                        bg='neutral.0'
                        borderRadius='md'
                        px={4}
                        py={2}
                        mx={2}
                        my={4}
                        width='auto'
                        data-testid='work-order-form-product'
                      >
                        <FormLabel>
                          <Flex gap={2} alignItems='center'>
                            {getDefaultFieldLabel('productId')}
                            {inputTagProps && <InputTag {...inputTagProps} />}
                          </Flex>
                        </FormLabel>
                        <MultipleLayerSelect
                          label={getDefaultFieldLabel('productId')}
                          value={value}
                          options={selectProductItems}
                          onChange={onChange}
                        />
                        {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
                        <VStack mt={2} alignItems='start' gap={0}>
                          <Heading size='xs'>{t('form.recently-used')}</Heading>
                          <Flex minH='40px'>
                            {!formData ? (
                              <Spinner color='primary.500' size='sm' alignSelf='center' />
                            ) : (
                              <WorkOrderRecentlyUsedValues
                                items={formData.recentlyUsedWorkOrderProducts}
                                onClick={(item) => onChange(item.id)}
                              />
                            )}
                          </Flex>
                        </VStack>
                      </FormControl>
                    )}
                    rules={{ required: required ? t('form.validation.required') : false }}
                  />
                )}

                {fieldOrder.type === WorkOrderTemplateFieldOrderType.Assignee && (
                  <Controller
                    control={methods.control}
                    name='assignees'
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <FormControl
                        isInvalid={!!error}
                        bg='neutral.0'
                        borderRadius='md'
                        px={4}
                        py={2}
                        mx={2}
                        my={4}
                        width='auto'
                        data-testid='work-order-form-assignee'
                      >
                        <FormLabel>
                          <Flex gap={2} alignItems='center'>
                            {getDefaultFieldLabel('assignees')}
                            {inputTagProps && <InputTag {...inputTagProps} />}
                          </Flex>
                        </FormLabel>
                        <UserSelectInput values={value} onChange={onChange} />
                        {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
                        <VStack mt={2} alignItems='start' gap={0}>
                          <Heading size='xs'>{t('form.recently-assigned-user')}</Heading>
                          <Flex minH='40px'>
                            {!formData ? (
                              <Spinner color='primary.500' size='sm' alignSelf='center' />
                            ) : (
                              <WorkOrderRecentlyUsedValues
                                items={formData.recentlyAssignedWorkOrderUsers}
                                onClick={(item) => {
                                  onChange(
                                    uniqBy([...value, { userId: item.id }], (user) => user.userId)
                                  );
                                }}
                                noItemLabel={t('form.recently-assigned-no-user')}
                              />
                            )}
                          </Flex>
                        </VStack>
                      </FormControl>
                    )}
                    rules={{
                      validate: {
                        required: (value) => {
                          if (!required || validateSelectRequired(value)) return true;
                          return t('form.validation.required');
                        },
                      },
                    }}
                  />
                )}

                {fieldOrder.type === WorkOrderTemplateFieldOrderType.Priority && (
                  <Controller
                    control={methods.control}
                    name='priority'
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <FormControl
                        isInvalid={!!error}
                        bg='neutral.0'
                        borderRadius='md'
                        px={4}
                        py={2}
                        mx={2}
                        my={4}
                        width='auto'
                        data-testid='work-order-form-priority'
                      >
                        <FormLabel>
                          <Flex gap={2} alignItems='center'>
                            {getDefaultFieldLabel('priority')}
                            {inputTagProps && <InputTag {...inputTagProps} />}
                          </Flex>
                        </FormLabel>
                        <WorkOrderPriorityRadio
                          priority={value}
                          updateWorkOrderPriority={onChange}
                        />
                        {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
                      </FormControl>
                    )}
                    rules={{
                      validate: {
                        required: (value) => {
                          if (!required || value !== WorkOrderPriority.None) return true;
                          return t('form.validation.required');
                        },
                      },
                    }}
                  />
                )}

                {fieldOrder.type === WorkOrderTemplateFieldOrderType.Stoppage && (
                  <Box bg='neutral.0' borderRadius='md' px={4} py={2} mx={2} my={4}>
                    <Controller
                      control={methods.control}
                      name='stoppageStartAt'
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <DateTimePicker
                          inputTagProps={inputTagProps}
                          label={getDefaultFieldLabel('stoppageStartAt')}
                          ariaLabel='stoppage-start-at'
                          value={value}
                          onChange={onChange}
                          locale={i18n.language}
                          isInvalid={!!error}
                          errorMessage={error?.message}
                        />
                      )}
                      rules={{
                        required: required ? t('form.validation.required') : false,
                        // NOTE: 停止開始時刻を変更することで停止終了時刻側のバリデーションを発火させる
                        deps: ['stoppageEndAt'],
                      }}
                    />
                    <Controller
                      control={methods.control}
                      name='stoppageEndAt'
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <DateTimePicker
                          inputTagProps={inputTagProps}
                          label={getDefaultFieldLabel('stoppageEndAt')}
                          ariaLabel='stoppage-end-at'
                          value={value}
                          onChange={onChange}
                          isInvalid={!!error}
                          errorMessage={error?.message}
                          locale={i18n.language}
                          sx={{ mt: 4 }}
                        />
                      )}
                      rules={{
                        required: required ? t('form.validation.required') : false,
                        validate: {
                          afterStartAt: (value, formValues) => {
                            if (isFromDateAfterToDate(formValues.stoppageStartAt, value)) {
                              return t('invalid-start-and-end-time', { ns: 'errors' });
                            }
                            return true;
                          },
                        },
                      }}
                    />
                    <WorkOrderDisplayDiffDownTime control={methods.control} />
                  </Box>
                )}

                {fieldOrder.type === WorkOrderTemplateFieldOrderType.StoppageReason && (
                  <Controller
                    control={methods.control}
                    name='stoppageReasonId'
                    render={({ field, fieldState: { error } }) => (
                      <FormControl
                        isInvalid={!!error}
                        bg='neutral.0'
                        borderRadius='md'
                        px={4}
                        py={2}
                        mx={2}
                        my={4}
                        width='auto'
                        data-testid='work-order-form-stoppage-reason'
                      >
                        <FormLabel>
                          <Flex gap={2} alignItems='center'>
                            {getDefaultFieldLabel('stoppageReasonId')}
                            {inputTagProps && <InputTag {...inputTagProps} />}
                          </Flex>
                        </FormLabel>
                        <WorkOrderStoppageReasonPicker
                          assetId={selectedAssetId ?? undefined}
                          additionalItem={workOrder?.stoppageReason}
                          value={field.value}
                          onChange={field.onChange}
                        />
                        {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
                      </FormControl>
                    )}
                    rules={{ required: required ? t('form.validation.required') : false }}
                  />
                )}

                {fieldOrder.type === WorkOrderTemplateFieldOrderType.CheckList &&
                  accessCheckList && (
                    <>
                      {checkListTemplate ? (
                        <SuspenseWithSpinner>
                          <WorkOrderCheckListCustomFieldList
                            isEdit
                            name={checkListTemplate.name}
                            description={checkListTemplate.description}
                            items={checkListTemplate.items}
                            checkListFormValue={checkListFormValue}
                            onChangeCheckListValue={onChangeCheckListFormValue}
                          />
                        </SuspenseWithSpinner>
                      ) : (
                        <Controller
                          control={methods.control}
                          name='checkLists'
                          render={({ field: { value, onChange }, fieldState: { error } }) => (
                            <FormControl
                              isInvalid={!!error}
                              bg='neutral.0'
                              borderRadius='md'
                              px={4}
                              py={2}
                              mx={2}
                              my={4}
                              width='auto'
                            >
                              <FormLabel fontWeight='bold'>
                                <Flex gap={2} alignItems='center'>
                                  {getDefaultFieldLabel('checkLists')}
                                  {inputTagProps && <InputTag {...inputTagProps} />}
                                </Flex>
                              </FormLabel>
                              <WorkOrderCheckListField
                                workOrderId={workOrder ? workOrder.id : undefined}
                                isEdit
                                checkLists={value}
                                onChangeCheckLists={onChange}
                                createCheckList={createCheckList}
                                deleteCheckList={deleteCheckList}
                              />
                              {error?.message && (
                                <FormErrorMessage>{error?.message}</FormErrorMessage>
                              )}
                            </FormControl>
                          )}
                          rules={{
                            validate: {
                              required: (value) => {
                                if (!required || validateSelectRequired(value)) return true;
                                return t('form.validation.required');
                              },
                            },
                          }}
                        />
                      )}
                    </>
                  )}

                {fieldOrder.type === WorkOrderTemplateFieldOrderType.Part && (
                  <Controller
                    control={methods.control}
                    name='parts'
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <WorkOrderPartField
                        workOrderId={workOrder ? workOrder.id : undefined}
                        assetId={selectedAssetId ?? undefined}
                        workOrderParts={value}
                        onChangeWorkOrderPart={onChange}
                        onUpdatePart={onUpdatePart}
                        inputTagProps={inputTagProps}
                        errorMessage={error?.message}
                      />
                    )}
                    rules={{
                      validate: {
                        required: (value) => {
                          if (!required || validateSelectRequired(value)) return true;
                          return t('form.validation.required');
                        },
                      },
                    }}
                  />
                )}

                {fieldOrder.type === WorkOrderTemplateFieldOrderType.CustomField &&
                  (() => {
                    const customField = customFields.find(
                      (entry) => entry.id === fieldOrder.customFieldId
                    );
                    if (!customField) return null;

                    return (
                      <CustomFieldItem
                        required={required}
                        inputTagProps={inputTagProps}
                        assetId={selectedAssetId ?? undefined}
                        customField={customField}
                        customFieldTextValues={customFieldTextLocalValues}
                        customFieldIntValues={customFieldIntLocalValues}
                        customFieldFloatValues={customFieldFloatLocalValues}
                        customFieldSelectValues={customFieldSelectLocalValues}
                        customFieldDateValues={customFieldDateLocalValues}
                        customFieldDatetimeValues={customFieldDatetimeLocalValues}
                        customFieldUserValues={customFieldUserLocalValues}
                        customFieldFileValues={customFieldFileLocalValues}
                        changeCustomFieldTextValues={setCustomFieldTextLocalValues}
                        changeCustomFieldIntValues={setCustomFieldIntLocalValues}
                        changeCustomFieldFloatValues={setCustomFieldFloatLocalValues}
                        changeCustomFieldSelectValues={setCustomFieldSelectLocalValues}
                        changeCustomFieldDateValues={setCustomFieldDateLocalValues}
                        changeCustomFieldDatetimeValues={setCustomFieldDatetimeLocalValues}
                        changeCustomFieldUserValues={setCustomFieldUserLocalValues}
                        onAddCustomFieldFileValues={handleAddCustomFieldFileValues}
                        onRemoveCustomFieldFileValue={handleRemoveCustomFieldFileValue}
                        getFileUploadUrls={getFileUploadUrls}
                        onUpdateCustomFieldFileValue={handleUpdateCustomFieldFileValue}
                      />
                    );
                  })()}
              </Box>
            );
          })}
      </Box>

      {isDesktop && (
        <Flex
          justifyContent='flex-end'
          width='full'
          left='0'
          py='2'
          px='4'
          backgroundColor='neutral.0'
          borderTop='1px'
          borderColor='neutral.200'
          zIndex={101}
          gap={2}
          height={{ base: 'auto', md: '54px' }}
        >
          <Button colorScheme='gray' width='auto' onClick={handleCancel}>
            {t('actions.cancel')}
          </Button>
          {usePauseButton && (
            <Button variant='outline' colorScheme='primary' width='auto' onClick={handlePause}>
              {t('actions.pause')}
            </Button>
          )}

          <Button
            colorScheme='primary'
            isLoading={isSubmitting}
            type='submit'
            width='100px'
            onClick={handleSubmit(onSubmit, onError)}
          >
            {submitButton}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

const WorkOrderDisplayDiffDownTime = ({ control }: { control: Control<WorkOrderFormType> }) => {
  const [startAt, endAt] = useWatch<WorkOrderFormType, ['stoppageStartAt', 'stoppageEndAt']>({
    control,
    name: ['stoppageStartAt', 'stoppageEndAt'],
  });
  return <DisplayDiffDownTime startAt={startAt} endAt={endAt} />;
};

export default WorkOrderForm;
