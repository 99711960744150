import {
  Button,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

type SelectProps<T extends string | number | boolean = string> = {
  options: {
    value: T;
    label: string;
  }[];
  value: T;
  onChange: (value: T) => void;
};

const Select = <T extends string | number | boolean = string>({
  options,
  value,
  onChange,
}: SelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: ref,
    handler: (e) => {
      if (
        (e.target instanceof HTMLElement || e.target instanceof SVGSVGElement) &&
        e.target.parentElement?.id === 'menu-parent'
      )
        return;

      setIsOpen(false);
    },
  });

  return (
    <Menu closeOnSelect={false} closeOnBlur={false} isOpen={true}>
      <HStack
        id='menu-parent'
        _hover={{
          bg: isOpen ? undefined : 'neutral.50',
          borderColor: 'neutral.300',
          color: ' neutral.800',
        }}
        border='1px solid'
        borderColor='neutral.300'
        borderRadius='md'
        spacing='0'
        bgColor={isOpen ? 'gray.200' : 'transparent'}
        minW={'100%'}
        minH={'40px'}
      >
        <MenuButton
          width={'full'}
          size='sm'
          variant='outline'
          color={'neutral.800'}
          border={0}
          p={0}
          as={Button}
          _hover={{
            color: 'neutral.800',
            bg: 'transparent',
          }}
          _active={{ bg: 'transparent' }}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <Flex justifyContent='space-between' alignItems='center' w='full' gap={2} pl={4} pr={3}>
            <Text fontWeight={'normal'} lineHeight={'base'} fontSize={'md'}>
              {options.find((option) => option.value === value)?.label}
            </Text>
            <IconButton
              as={'div'}
              p={0}
              minW={'0'}
              variant='unstyled'
              display='flex'
              justifyContent='center'
              alignItems='center'
              height='100%'
              _active={{}}
              _hover={{ background: 'transparent' }}
              aria-label='Select options'
              color={isOpen ? 'primary.500' : 'neutral.500'}
              icon={isOpen ? <MdExpandLess size={20} /> : <MdExpandMore size={20} />}
              onClick={() => setIsOpen(!isOpen)}
            />
          </Flex>
        </MenuButton>
      </HStack>
      {isOpen && (
        <Portal>
          <MenuList ref={ref} w='full' minW={150} zIndex={2000}>
            {options.map(({ value, label }) => {
              return (
                <MenuItem
                  key={value.toString()}
                  onClick={() => {
                    onChange(value);
                    setIsOpen(false);
                  }}
                >
                  {label}
                </MenuItem>
              );
            })}
          </MenuList>
        </Portal>
      )}
    </Menu>
  );
};

export default Select;
