import * as Types from '../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddPartFilesMutationVariables = Types.Exact<{
  newPartFilesInput: Types.NewPartFilesInput;
}>;


export type AddPartFilesMutation = (
  { __typename?: 'Mutation' }
  & { addPartFiles: (
    { __typename?: 'Part' }
    & Pick<Types.Part, 'id'>
    & { files: Array<(
      { __typename?: 'PartFile' }
      & Pick<Types.PartFile, 'partId' | 'name' | 'contentType' | 'fileId' | 'path'>
    )> }
  ) }
);

export type AddPartDocumentsMutationVariables = Types.Exact<{
  newPartDocumentsInput: Types.NewPartDocumentsInput;
}>;


export type AddPartDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { addPartDocuments: (
    { __typename?: 'Part' }
    & Pick<Types.Part, 'id'>
    & { documents: Array<(
      { __typename?: 'PartDocument' }
      & Pick<Types.PartDocument, 'partId' | 'name' | 'contentType' | 'fileId'>
    )> }
  ) }
);


export const AddPartFilesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddPartFiles"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newPartFilesInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"NewPartFilesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addPartFiles"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"newPartFilesInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newPartFilesInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"files"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partId"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"contentType"}},{"kind":"Field","name":{"kind":"Name","value":"fileId"}},{"kind":"Field","name":{"kind":"Name","value":"path"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AddPartFilesMutationFn = Apollo.MutationFunction<AddPartFilesMutation, AddPartFilesMutationVariables>;

/**
 * __useAddPartFilesMutation__
 *
 * To run a mutation, you first call `useAddPartFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartFilesMutation, { data, loading, error }] = useAddPartFilesMutation({
 *   variables: {
 *      newPartFilesInput: // value for 'newPartFilesInput'
 *   },
 * });
 */
export function useAddPartFilesMutation(baseOptions?: Apollo.MutationHookOptions<AddPartFilesMutation, AddPartFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPartFilesMutation, AddPartFilesMutationVariables>(AddPartFilesDocument, options);
      }
export type AddPartFilesMutationHookResult = ReturnType<typeof useAddPartFilesMutation>;
export type AddPartFilesMutationResult = Apollo.MutationResult<AddPartFilesMutation>;
export type AddPartFilesMutationOptions = Apollo.BaseMutationOptions<AddPartFilesMutation, AddPartFilesMutationVariables>;
export const AddPartDocumentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddPartDocuments"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newPartDocumentsInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"NewPartDocumentsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addPartDocuments"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"newPartDocumentsInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newPartDocumentsInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"documents"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partId"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"contentType"}},{"kind":"Field","name":{"kind":"Name","value":"fileId"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AddPartDocumentsMutationFn = Apollo.MutationFunction<AddPartDocumentsMutation, AddPartDocumentsMutationVariables>;

/**
 * __useAddPartDocumentsMutation__
 *
 * To run a mutation, you first call `useAddPartDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartDocumentsMutation, { data, loading, error }] = useAddPartDocumentsMutation({
 *   variables: {
 *      newPartDocumentsInput: // value for 'newPartDocumentsInput'
 *   },
 * });
 */
export function useAddPartDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<AddPartDocumentsMutation, AddPartDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPartDocumentsMutation, AddPartDocumentsMutationVariables>(AddPartDocumentsDocument, options);
      }
export type AddPartDocumentsMutationHookResult = ReturnType<typeof useAddPartDocumentsMutation>;
export type AddPartDocumentsMutationResult = Apollo.MutationResult<AddPartDocumentsMutation>;
export type AddPartDocumentsMutationOptions = Apollo.BaseMutationOptions<AddPartDocumentsMutation, AddPartDocumentsMutationVariables>;