import MultiLayerFilter from '@/common/components/MultiLayerFilter';
import { useApplicationContext } from '@/context/ApplicationContext';
import useTranslation from '@/utils/i18n/useTranslation';
import { Box, HStack, Switch, Text } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { MdGroups } from 'react-icons/md';
import { WorkOrderCalendarFilterFormValues } from '../WorkOrderFilterableCalendarView';
import {
  WorkOrderFilterableCalendarView_AssigneeFragment,
  WorkOrderFilterableCalendarView_GroupFragment,
} from '../WorkOrderFilterableCalendarView.generated';

type WorkOrderCalendarFilterProps = {
  control: Control<WorkOrderCalendarFilterFormValues>;
  users: WorkOrderFilterableCalendarView_AssigneeFragment[];
  groups: WorkOrderFilterableCalendarView_GroupFragment[];
};

const WorkOrderCalendarFilter = ({ control, users, groups }: WorkOrderCalendarFilterProps) => {
  const { companySetting } = useApplicationContext();
  return (
    <Box
      p={2}
      sx={{
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        overflowY: 'hidden',
      }}
    >
      <HStack spacing='12px'>
        {companySetting?.accessGroup && (
          <WorkOrderCalendarGroupFilter control={control} groups={groups} />
        )}
        <WorkOrderCalendarAssigneeFilter control={control} users={users} />
      </HStack>
    </Box>
  );
};

type WorkOrderCalendarGroupFilterProps = {
  control: Control<WorkOrderCalendarFilterFormValues>;
  groups: WorkOrderFilterableCalendarView_GroupFragment[];
};

const WorkOrderCalendarGroupFilter = ({ control, groups }: WorkOrderCalendarGroupFilterProps) => {
  const { groupsByUser } = useApplicationContext();
  const { t } = useTranslation();
  const [isCheckedMyFilter, setIsCheckedMyFilter] = useState(false);

  const options = useMemo(() => {
    if (!groups) return [];
    return groups.map(({ id, name }) => ({ id: Number(id), label: name }));
  }, [groups]);
  return (
    <Controller<WorkOrderCalendarFilterFormValues, 'groups'>
      name='groups'
      control={control}
      render={({ field: { value, onChange } }) => (
        <MultiLayerFilter
          customSettingComponent={
            <HStack justifyContent='space-between' m={2}>
              <Text size='sm'> {t('belonging-groups-only')}</Text>
              <Switch
                onChange={() => {
                  onChange(isCheckedMyFilter ? [] : groupsByUser.map((group) => Number(group.id)));
                  setIsCheckedMyFilter((prev) => !prev);
                }}
                isChecked={isCheckedMyFilter}
                size='sm'
              />
            </HStack>
          }
          icon={<MdGroups size='32px' />}
          label={t('group')}
          values={value}
          options={options}
          onChange={(values = []) => onChange(values)}
        />
      )}
    />
  );
};

type WorkOrderCalendarAssigneeFilterProps = {
  control: Control<WorkOrderCalendarFilterFormValues>;
  users: WorkOrderFilterableCalendarView_AssigneeFragment[];
};

const WorkOrderCalendarAssigneeFilter = ({
  control,
  users,
}: WorkOrderCalendarAssigneeFilterProps) => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    if (!users) return [];
    return users.map(({ id, name }) => ({ id, label: name }));
  }, [users]);
  return (
    <Controller<WorkOrderCalendarFilterFormValues, 'assignees'>
      name='assignees'
      control={control}
      render={({ field: { value, onChange } }) => (
        <MultiLayerFilter
          label={t('assignee')}
          values={value}
          options={options}
          onChange={(values = []) => onChange(values)}
        />
      )}
    />
  );
};

export default WorkOrderCalendarFilter;
