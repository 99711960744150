import { Grid, GridItem, GridProps } from '@chakra-ui/react';
import dayjs from 'dayjs';

export type MultiDaysViewDayEvent = {
  id: number;
  renderChip: () => JSX.Element;
  startDate: Date;
  endDate?: Date;
};

type MultiDaysEventChipAlignerProps = {
  gridProps: GridProps;
  events: MultiDaysViewDayEvent[];
  dates: Date[];
};

// NOTE: スマホでは代わりにMultiDaysEventCountDisplayを使うためスマホサイズのResponsiveは考慮しない
const MultiDaysEventChipAligner = ({
  gridProps,
  events,
  dates,
}: MultiDaysEventChipAlignerProps) => {
  const baseDate = dates[0];
  return (
    <Grid
      p={1}
      templateColumns={'subgrid'}
      gridAutoFlow={'column'}
      autoRows={'max-content'}
      minH={'100px'}
      gap={0.5}
      {...gridProps}
    >
      {events
        .filter((event) => isDateInView(event, dates))
        .map((event) => {
          const diffBetweenStartAndBase = dayjs(event.startDate).diff(baseDate, 'day');
          const diffBetweenEndAndBase = event.endDate
            ? dayjs(event.endDate).diff(baseDate, 'day')
            : diffBetweenStartAndBase; // 期間がない場合は1日として表示する
          const gridColumnStart = (diffBetweenStartAndBase >= 0 ? diffBetweenStartAndBase : 0) + 1; // グリッド線は1から始まるため+1
          const gridColumnEnd =
            (diffBetweenEndAndBase <= dates.length ? diffBetweenEndAndBase : dates.length) + 2; // グリッド線は1から始まるため+1、グリッド線の数はトラックの数より1大きいため+1
          const roundedLeft = diffBetweenStartAndBase >= 0 ? 'full' : 'none';
          const roundedRight = diffBetweenEndAndBase <= dates.length ? 'full' : 'none';
          return (
            <GridItem
              key={event.id}
              gridColumnStart={gridColumnStart}
              gridColumnEnd={gridColumnEnd}
              sx={{
                '> *': {
                  roundedLeft,
                  roundedRight,
                },
              }}
              zIndex={1}
            >
              {event.renderChip()}
            </GridItem>
          );
        })}
    </Grid>
  );
};

const isDateInView = (event: MultiDaysViewDayEvent, dates: Date[]) => {
  const firstDateInView = dates[0];
  const lastDateInView = dates[dates.length - 1];
  const isStartDateBeforeLastDateInView =
    event.startDate <= dayjs(lastDateInView).endOf('day').toDate();
  const isEndDateAfterFirstDateInView = event.endDate
    ? event.endDate >= dayjs(firstDateInView).startOf('day').toDate()
    : true; // 期間がない場合はstartDateのみで判定する
  return isStartDateBeforeLastDateInView || isEndDateAfterFirstDateInView;
};

export default MultiDaysEventChipAligner;
