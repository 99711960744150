import { IMAGE_EDITOR } from '@/utils/i18n/constants';
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  useId,
  useToken,
} from '@chakra-ui/react';
import { ArrowTopRightIcon, CircleHollowIcon, CrossIcon, TrashIcon } from '@storybook/icons';
import { fabric } from 'fabric';
import { FC, useLayoutEffect, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { FaCircle } from 'react-icons/fa';

export type Editor = {
  export: (originalFileName: string) => {
    dataUri: string;
    contentType: string;
    name: string;
  };
};

type Props = {
  src: string;
  onEditorReady: (editor: Editor) => void;
};

const ImageEditor: FC<Props> = ({ src, onEditorReady }) => {
  const canvasContainerId = useId();
  const canvasId = useId();

  const fabricCanvas = useRef<fabric.Canvas | null>(null);
  const [objectSize, setObjectSize] = useState(1);

  const { t } = useTranslation();
  const [redHex, primaryHex, whiteHex, yellowHex, neutralHex, blueHex] = useToken('colors', [
    'red',
    'primary.500',
    'neutral.0',
    'warning.500',
    'neutral.900',
    'blue.400',
  ]);

  const colorOptions = [redHex, whiteHex, neutralHex, blueHex, yellowHex];
  const [selectedColor, setSelectedColor] = useState(redHex);

  useLayoutEffect(() => {
    if (!fabricCanvas.current) {
      const canvas = new fabric.Canvas(canvasId, { width: 0, height: 0 });
      fabricCanvas.current = canvas;

      fabric.Image.fromURL(
        src,
        (img) => {
          if (
            !img.width ||
            !img.height ||
            !(canvas as unknown as { lowerCanvasEl?: unknown }).lowerCanvasEl
          )
            return;

          // canvasが表示領域に収まるようにscaleを設定
          const canvasContainer = document.getElementById(canvasContainerId);
          const scale = Math.min(
            (canvasContainer?.clientWidth ?? 0) / img.width,
            (canvasContainer?.clientHeight ?? 0) / img.height
          );

          const [canvasWidth, canvasHeight] = [img.width * scale, img.height * scale];
          canvas.setDimensions({
            width: canvasWidth,
            height: canvasHeight,
          });
          img.scaleToHeight(canvasHeight, true);
          img.scaleToWidth(canvasWidth, true);
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));

          // 配置するオブジェクトのサイズは元画像への割合で設定（高解像度画像でも小さくならないように）
          const objectSize = Math.floor(Math.min(canvasWidth * 0.2, canvasHeight * 0.2));
          setObjectSize(objectSize);

          // fabric.jsのコントロールの初期設定
          initializeFabricControls(canvas, objectSize, primaryHex, redHex);

          onEditorReady({
            export: (originalFileName) => {
              const dataUri = canvas.toDataURL({
                format: 'png',
                multiplier: 1 / scale,
              });
              return {
                dataUri: dataUri,
                contentType: 'image/png',
                name: replaceFileExtension(originalFileName, '.png'),
              };
            },
          });
        },
        {
          crossOrigin: 'anonymous',
        }
      );
    }

    return () => {
      fabricCanvas.current?.dispose();
      fabricCanvas.current = null;
    };
  }, [canvasContainerId, canvasId, onEditorReady, primaryHex, redHex, src]);

  const onClickAddText = () => {
    const text = new fabric.IText(t('input-text', { ns: IMAGE_EDITOR }), {
      fill: selectedColor,
      fontSize: objectSize / 2,
      // @ts-ignore
      // see https://github.com/fabricjs/fabric.js/issues/3695#issuecomment-1253889317
      hiddenTextareaContainer: window.document.getElementById(canvasId).parentNode,
    });
    fabricCanvas.current?.add(text);
    fabricCanvas.current?.centerObject(text);
    fabricCanvas.current?.setActiveObject(text);
    text.enterEditing();
    text.selectAll();
  };

  const onClickAddArrow = () => {
    fabric.loadSVGFromString(
      renderToStaticMarkup(
        <ArrowTopRightIcon width={objectSize} height={objectSize} color={selectedColor} />
      ),
      ([image]) => {
        fabricCanvas.current?.add(image)?.centerObject(image)?.setActiveObject(image);
      }
    );
  };

  const onClickAddCircle = () => {
    fabric.loadSVGFromString(
      renderToStaticMarkup(
        <CircleHollowIcon width={objectSize} height={objectSize} color={selectedColor} />
      ),
      ([image]) => {
        fabricCanvas.current?.add(image)?.centerObject(image)?.setActiveObject(image);
      }
    );
  };

  const onClickAddCross = () => {
    fabric.loadSVGFromString(
      renderToStaticMarkup(
        <CrossIcon width={objectSize} height={objectSize} color={selectedColor} />
      ),
      ([image]) => {
        fabricCanvas.current?.add(image)?.centerObject(image)?.setActiveObject(image);
      }
    );
  };

  const onClickChooseColor = (color: string) => {
    setSelectedColor(color);
  };

  return (
    <Flex direction='column' padding={2} gap={2} height='100%'>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        flex='1'
        overflow='clip'
        id={canvasContainerId}
      >
        <canvas id={canvasId} />
      </Box>
      <Center>
        <ButtonGroup>
          <Button variant='outline' colorScheme='primary' onClick={onClickAddText}>
            {t('add-text', { ns: IMAGE_EDITOR })}
          </Button>
          <IconButton
            aria-label={t('add-arrow', { ns: IMAGE_EDITOR })}
            variant='outline'
            colorScheme='primary'
            onClick={onClickAddArrow}
            icon={<ArrowTopRightIcon />}
          />
          <IconButton
            aria-label={t('add-circle', { ns: IMAGE_EDITOR })}
            variant='outline'
            colorScheme='primary'
            onClick={onClickAddCircle}
            icon={<CircleHollowIcon />}
          />
          <IconButton
            aria-label={t('add-cross', { ns: IMAGE_EDITOR })}
            variant='outline'
            colorScheme='primary'
            onClick={onClickAddCross}
            icon={<CrossIcon />}
          />
          <Popover variant='picker' placement='top'>
            <PopoverTrigger>
              <IconButton
                aria-label={t('choose-color', { ns: IMAGE_EDITOR })}
                variant='outline'
                colorScheme='primary'
                fontSize='2xl'
                icon={<FaCircle color={selectedColor} />}
              />
            </PopoverTrigger>
            <PopoverContent width='fit-content' bg='neutral.700' borderColor='primary.500'>
              <PopoverBody>
                <SimpleGrid columns={5} spacing={2}>
                  {colorOptions.map((color) => (
                    <Button
                      key={color}
                      aria-label={color}
                      background={color}
                      _hover={{ background: color, transform: 'scale(1.1)' }}
                      size='xs'
                      borderRadius={3}
                      onClick={() => onClickChooseColor(color)}
                    />
                  ))}
                </SimpleGrid>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </ButtonGroup>
      </Center>
    </Flex>
  );
};

const replaceFileExtension = (fileName: string, newExtension: string) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  return lastDotIndex < 0
    ? fileName + newExtension
    : fileName.substring(0, lastDotIndex) + newExtension;
};

function initializeFabricControls(
  canvas: fabric.Canvas,
  objectSize: number,
  primaryColor: string,
  cautionColor: string
) {
  fabric.Object.prototype.set({
    cornerSize: objectSize * 0.05,
    padding: objectSize * 0.05,
    borderColor: primaryColor,
    cornerColor: primaryColor,
    borderScaleFactor: objectSize * 0.025,
    borderDashArray: [objectSize * 0.025, objectSize * 0.025],
    transparentCorners: false,
  });

  const deleteButtonSize = objectSize * 0.1;
  fabric.Object.prototype.controls.deleteControl = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: -deleteButtonSize * 2,
    offsetX: deleteButtonSize * 2,
    sizeX: deleteButtonSize,
    sizeY: deleteButtonSize,
    cursorStyle: 'pointer',
    mouseUpHandler: (_, transform) => {
      canvas.remove(transform.target);
      return true;
    },
    render: (ctx, left, top, _styleOverride, fabricObject) => {
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle ?? 0));

      ctx.beginPath();
      ctx.arc(0, 0, deleteButtonSize, 0, 2 * Math.PI);
      ctx.fillStyle = 'white';
      ctx.fill();
      ctx.strokeStyle = cautionColor;
      ctx.lineWidth = deleteButtonSize * 0.1;
      ctx.stroke();
      ctx.restore();

      const image = new Image();
      image.onload = () => {
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle ?? 0));
        ctx.drawImage(
          image,
          -deleteButtonSize / 2,
          -deleteButtonSize / 2,
          deleteButtonSize,
          deleteButtonSize
        );
        ctx.restore();
      };
      image.src =
        'data:image/svg+xml,' +
        renderToStaticMarkup(<TrashIcon color={cautionColor} size={deleteButtonSize} />);
    },
  });
}

export default ImageEditor;
