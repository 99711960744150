import * as Types from '../../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkOrderFilterableCalendarView_AssigneeFragment = (
  { __typename?: 'User' }
  & Pick<Types.User, 'id' | 'name'>
);

export type WorkOrderFilterableCalendarView_GroupFragment = (
  { __typename?: 'Group' }
  & Pick<Types.Group, 'id' | 'name'>
);

export type WorkOrderFilterableCalendarView_GetFilterOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WorkOrderFilterableCalendarView_GetFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<Types.User, 'id' | 'name'>
  )>, groups: Array<(
    { __typename?: 'Group' }
    & Pick<Types.Group, 'id' | 'name'>
  )> }
);

export const WorkOrderFilterableCalendarView_AssigneeFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WorkOrderFilterableCalendarView_Assignee"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode;
export const WorkOrderFilterableCalendarView_GroupFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WorkOrderFilterableCalendarView_Group"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Group"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode;
export const WorkOrderFilterableCalendarView_GetFilterOptionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"WorkOrderFilterableCalendarView_GetFilterOptions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"users"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"WorkOrderFilterableCalendarView_Assignee"}}]}},{"kind":"Field","name":{"kind":"Name","value":"groups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"WorkOrderFilterableCalendarView_Group"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WorkOrderFilterableCalendarView_Assignee"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WorkOrderFilterableCalendarView_Group"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Group"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode;

/**
 * __useWorkOrderFilterableCalendarView_GetFilterOptionsQuery__
 *
 * To run a query within a React component, call `useWorkOrderFilterableCalendarView_GetFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOrderFilterableCalendarView_GetFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkOrderFilterableCalendarView_GetFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkOrderFilterableCalendarView_GetFilterOptionsQuery(baseOptions?: Apollo.QueryHookOptions<WorkOrderFilterableCalendarView_GetFilterOptionsQuery, WorkOrderFilterableCalendarView_GetFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkOrderFilterableCalendarView_GetFilterOptionsQuery, WorkOrderFilterableCalendarView_GetFilterOptionsQueryVariables>(WorkOrderFilterableCalendarView_GetFilterOptionsDocument, options);
      }
export function useWorkOrderFilterableCalendarView_GetFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkOrderFilterableCalendarView_GetFilterOptionsQuery, WorkOrderFilterableCalendarView_GetFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkOrderFilterableCalendarView_GetFilterOptionsQuery, WorkOrderFilterableCalendarView_GetFilterOptionsQueryVariables>(WorkOrderFilterableCalendarView_GetFilterOptionsDocument, options);
        }
export function useWorkOrderFilterableCalendarView_GetFilterOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkOrderFilterableCalendarView_GetFilterOptionsQuery, WorkOrderFilterableCalendarView_GetFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkOrderFilterableCalendarView_GetFilterOptionsQuery, WorkOrderFilterableCalendarView_GetFilterOptionsQueryVariables>(WorkOrderFilterableCalendarView_GetFilterOptionsDocument, options);
        }
export type WorkOrderFilterableCalendarView_GetFilterOptionsQueryHookResult = ReturnType<typeof useWorkOrderFilterableCalendarView_GetFilterOptionsQuery>;
export type WorkOrderFilterableCalendarView_GetFilterOptionsLazyQueryHookResult = ReturnType<typeof useWorkOrderFilterableCalendarView_GetFilterOptionsLazyQuery>;
export type WorkOrderFilterableCalendarView_GetFilterOptionsSuspenseQueryHookResult = ReturnType<typeof useWorkOrderFilterableCalendarView_GetFilterOptionsSuspenseQuery>;
export type WorkOrderFilterableCalendarView_GetFilterOptionsQueryResult = Apollo.QueryResult<WorkOrderFilterableCalendarView_GetFilterOptionsQuery, WorkOrderFilterableCalendarView_GetFilterOptionsQueryVariables>;