import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { gql } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import { Control, useForm, useWatch } from 'react-hook-form';
import WorkOrderCalendarView from '../WorkOrderCalendarView/WorkOrderCalendarView';
import { useWorkOrderFilterableCalendarView_GetFilterOptionsSuspenseQuery } from './WorkOrderFilterableCalendarView.generated';
import WorkOrderCalendarFilter from './internal/WorkOrderCalendarFilter';

export type WorkOrderCalendarFilterFormValues = {
  groups: number[];
  assignees: string[];
};

gql`
fragment WorkOrderFilterableCalendarView_Assignee on User {
  id
  name
}
fragment WorkOrderFilterableCalendarView_Group on Group {
  id
  name
}
  query WorkOrderFilterableCalendarView_GetFilterOptions {
    users {
      ...WorkOrderFilterableCalendarView_Assignee
    }
    groups {
      ...WorkOrderFilterableCalendarView_Group
    }
  }
`;

type WorkOrderFilterableCalendarViewProps = {
  isSPFilterOpen: boolean;
};

const WorkOrderFilterableCalendarView = (props: WorkOrderFilterableCalendarViewProps) => {
  return (
    <SuspenseWithSpinner>
      <InternalWorkOrderFilterableCalendarView {...props} />
    </SuspenseWithSpinner>
  );
};

const InternalWorkOrderFilterableCalendarView = ({
  isSPFilterOpen,
}: WorkOrderFilterableCalendarViewProps) => {
  const { data } = useWorkOrderFilterableCalendarView_GetFilterOptionsSuspenseQuery();
  const users = data?.users ?? [];
  const groups = data?.groups ?? [];
  const { isDesktop } = useScreenInfos();

  const { control } = useForm<WorkOrderCalendarFilterFormValues>({
    defaultValues: {
      groups: [],
      assignees: [],
    },
  });

  return (
    <Flex height={'100%'} direction={'column'}>
      {(isSPFilterOpen || isDesktop) && (
        <Box flexShrink={0}>
          <WorkOrderCalendarFilter control={control} users={users} groups={groups} />
        </Box>
      )}
      <Box flexGrow={1} overflowY={'hidden'}>
        <WatchedWorkOrderCalendarView control={control} />
      </Box>
    </Flex>
  );
};

type WatchedWorkOrderCalendarViewProps = {
  control: Control<WorkOrderCalendarFilterFormValues>;
};

const WatchedWorkOrderCalendarView = ({ control }: WatchedWorkOrderCalendarViewProps) => {
  const [selectedGroupIds, selectedAssigneeIds] = useWatch({
    control,
    name: ['groups', 'assignees'],
  });
  return (
    <WorkOrderCalendarView
      selectedGroupIds={selectedGroupIds}
      selectedAssigneeIds={selectedAssigneeIds}
    />
  );
};

export default WorkOrderFilterableCalendarView;
